import React, { useEffect, useState } from "react";
import classNames from "classnames";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { darkBlueColor, grayTextColor, yelloColor } from "../../Utils/index";
import AdmissionEnquiry from "views/Admission/AdmissionEnquiry";

const useStyles = makeStyles(styles);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: darkBlueColor,
    fontSize: 18,
    fontFamily: 'roboto',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 16,
    fontFamily: 'roboto',
    fontWeight: 500,
    color: darkBlueColor,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {},
  },
}))(TableRow);

export default function Bca(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function createData(name, calories) {
    return { name, calories };
  }

  const rows = [
    createData("Duration", "3 Years"),
    createData(
      "Minimum Eligibility",
      "The candidate must have passed 12th with 50% minimum aggregate with any stream."
    ),
    createData(
      "Scholarship",
      "Candidates belonging to reserved categories and economically weaker sections are eligible for state government scholarship schemes."
    ),
    createData(
      "Career Options",
      "Teacher & Lecturer Computer Programmer Software Developer Marketing Manager Business Consultant Computer Systems Analyst Finance Manager Computer Support Specialist Service Support Specialist"
    ),
  ];

  function createBcaData(sno, name, feesOne, feesTwo, FeesThree) {
    return { sno, name, feesOne, feesTwo, FeesThree };
  };

  const bcaRows = [
    createBcaData(1, "ACE FORM & PROSPECTUS FEES", 100, 0, 0),
    createBcaData(2, "ACE REGISTRATION FEES", "1,400", 0, 0),
    createBcaData(3, "IDENTITY CARD", 100, 0, 0),
    createBcaData(4, "BOOK BANK FEES", 800, 800, 800),
    createBcaData(5, "CAUTION FEES (Refundable Security)", "1,000", 0, 0),
    createBcaData(6, "UNIVERSITY FEES (As Per University Norms)", 850, 850, 850),
    createBcaData(7, "COLLEGE ACTIVITY FEES", 300, 300, 300),
    createBcaData(8, "TUITION FEES", "19,450", "21,050", "22,550"),
    createBcaData(9, "PRACTICAL", "1,000", "1,500", "1,500"),
  ];

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <Header
        color="white"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <div
        className={classNames(classes.main)}
        style={{ marginTop: 110, backgroundColor: "white" }}
      >
        <AdmissionEnquiry
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          courseName={"bca"}
        />
        <div>
          <div className={classes.container} style={{ padding: 30 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={9} lg={9}>
                <h2
                  style={{
                    fontSize: "30px",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "bold",
                    color: darkBlueColor,
                    marginBottom: 40,
                  }}
                >
                  Bachelor of Computer Applications (BCA)
                </h2>

                <div>
                  <p
                    style={{
                      color: grayTextColor,
                      fontSize: 16,
                      fontFamily: "Rubik, sans-serif",
                      textAlign: "justify",
                    }}
                  >
                    Bachelor of Computer Applications is a three-year
                    undergraduate course which deals with information technology
                    and computer applications. The course imparts knowledge
                    about different computer applications and how to solve and
                    address the problems which arise from a computer and its
                    applications. The course includes subjects such as core
                    programming languages Java and C++, data structure,
                    networking and others. BCA provides various opportunities to
                    the students who wish to pursue their career in IT and
                    software.
                  </p>

                  <h5
                    style={{
                      marginTop: 30,
                      fontSize: 20,
                      fontWeight: "bold",
                      fontFamily: "Rubik, sans-serif",
                      color: darkBlueColor,
                    }}
                  >
                    Scope of BCA
                  </h5>
                  <p
                    style={{
                      color: grayTextColor,
                      fontSize: 16,
                      fontFamily: "Rubik, sans-serif",
                      textAlign: "justify",
                      marginBottom: 50,
                    }}
                  >
                    There is no doubt that BCA is one of the best educational
                    courses that one can go for and there is ample amount of
                    opportunities for students studying BCA in the future.
                    Someone who has studied BCA can join a software company or
                    can also go for higher studies. In BCA subjects which are
                    included are almost similar to that of students pursuing
                    BTech. India and other countries many MNCs are recruiting
                    BCA graduates for their technical section.
                  </p>
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                style={{ marginTop: 30, marginBottom: 30 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Paper style={{ backgroundColor: "#f9f9f9", padding: 15 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography
                            style={{
                              color: "#7b838a",
                              fontFamily: "Rubik, sans-serif",
                            }}
                          >
                            Course Duration
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            style={{
                              color: darkBlueColor,
                              textAlign: "right",
                              fontFamily: "Rubik, sans-serif",
                            }}
                          >
                            3 Years
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      style={{
                        padding: 10,
                        backgroundColor: yelloColor,
                        color: darkBlueColor,
                        fontFamily: "Rubik, sans-serif",
                        fontWeight: "bold",
                      }}
                      variant="contained"
                      fullWidth
                      onClick={() => setOpenModal(true)}
                    >
                      Enroll the course
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">Level</StyledTableCell>
                    <StyledTableCell align="center">
                      Undergraduate
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.calories}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <h2
              style={{
                fontSize: "30px",
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: darkBlueColor,
                marginBottom: 40,
                marginTop: 40,
                textAlign: 'center'
              }}
            >
              Fees Structure (JNVU-2023)
            </h2>

            <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        {/* <StyledTableCell align="left">S. No</StyledTableCell> */}
                        <StyledTableCell align="left">Particular</StyledTableCell>
                        <StyledTableCell align="center">1st Year</StyledTableCell>
                        <StyledTableCell align="center">2nd Year</StyledTableCell>
                        <StyledTableCell align="center">3rd Year</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bcaRows.map((row) => (
                        <StyledTableRow key={row.name} style={{ marginTop: 200 }}>
                          {/* <StyledTableCell
                            align="left"
                          >
                            {row.sno}
                          </StyledTableCell> */}
                          <StyledTableCell
                            component="th"
                            scope="row"
                            align="left"
                          >
                            {row.name}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.feesOne}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {row.feesTwo}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {row.FeesThree}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                      <StyledTableRow style={{ backgroundColor: darkBlueColor }}>
                          {/* <StyledTableCell
                            align="left"
                            style={{ 
                              fontSize: 18,
                              color: 'white',
                              fontWeight: 'bold'
                            }}
                          >
                            10
                          </StyledTableCell> */}
                          <StyledTableCell
                            component="th"
                            align="left"
                            style={{ 
                              fontSize: 18,
                              color: 'white',
                              fontWeight: 'bold'
                            }}
                          >
                            TOTAL
                          </StyledTableCell>
                          <StyledTableCell 
                            align="center"
                            style={{ 
                              fontSize: 18,
                              color: 'white',
                              fontWeight: 'bold'
                            }}
                          >
                            25,000
                          </StyledTableCell>

                          <StyledTableCell 
                            align="center"
                            style={{ 
                              fontSize: 18,
                              color: 'white',
                              fontWeight: 'bold'
                            }}
                          >
                            24,500
                          </StyledTableCell>

                          <StyledTableCell 
                            align="center"
                            style={{ 
                              fontSize: 18,
                              color: 'white',
                              fontWeight: 'bold'
                            }}
                          >
                            26,000
                          </StyledTableCell>
                        </StyledTableRow>
                    </TableBody>
                  </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
