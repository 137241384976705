import React, { useEffect } from "react";
import classNames from "classnames";
import {
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { darkBlueColor, grayTextColor } from "../../Utils/index";
import sa1 from "assets/img/sa1.jpg";
import sa2 from "assets/img/sa2.jpg";
import sa3 from "assets/img/sa3.jpg";
import sa4 from "assets/img/sa4.jpg";

const useStyles = makeStyles(styles);

export default function SportsActivities(props) {
  const classes = useStyles();
  const { ...rest } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header
        color="white"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <div
        className={classNames(classes.main)}
        style={{ marginTop: 110, backgroundColor: "white" }}
      >
        <div>
          <div className={classes.container} style={{ padding: 30 }}>
            <h2
              style={{
                fontSize: "30px",
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: darkBlueColor,
                marginBottom: 40,
              }}
            >
              Sports Activities
            </h2>

            <div style={{ marginTop: 50, marginBottom: 50 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <img width={"100%"} height={"100%"} src={sa1} />
                </Grid>
                <Grid item xs={6}>
                  <img width={"100%"} height={"100%"} src={sa2} />
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{ marginTop: 10 }}>
                <Grid item xs={6}>
                  <img width={"100%"} height={"100%"} src={sa3} />
                </Grid>
                <Grid item xs={6}>
                  <img width={"100%"} height={"100%"} src={sa4} />
                </Grid>
              </Grid>
            </div>

            <div>
              <p
                style={{
                  color: grayTextColor,
                  fontSize: 16,
                  fontFamily: "Rubik, sans-serif",
                  textAlign: "justify",
                }}
              >
                A healthy mind resides in a healthy body. Understanding the
                importance of an agile body and its multiple health benefits,
                ACE-Pali offers innumerable sports opportunities to every
                student by organizing various inter college and zonal sports
                events. The College boasts of different sports facilities in the
                campus itself like chess, carom along with grounds for outdoor
                sports like football, volleyball, kabaddi, kho – kho and
                standard track for athletics. There are a wide number of
                activities and programs available to students both on campus and
                in the community. Each of these extracurricular activities has
                their own short and long-term benefits.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
