import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { darkBlueColor, grayTextColor, yelloColor } from "../../../Utils/index";
import logo from "assets/img/logo.png";
import directorImage from "assets/img/director.png";
import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer
        justify="center"
        style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 20, marginBottom: 50, backgroundColor: yelloColor }}
      >
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <h2
            style={{
              fontSize: "30px",
              fontFamily: "Rubik, sans-serif",
              fontWeight: "bold",
              color: darkBlueColor,
              marginBottom: 40,
            }}
          >
            Welcome to Aishwarya College of Education
          </h2>
          <p
            style={{
              color: darkBlueColor,
              fontSize: 16,
              fontFamily: "Rubik, sans-serif",
              textAlign: "justify",
              paddingLeft: 30,
              paddingRight: 30
            }}
          >
            When education sees its course, a dream opens its eyes”, is the
            belief of the foundation under the aegis of which top degree
            colleges in our states are set up. Education is just a spark for
            which it ignites the minds and inflames the intellect. Ignited minds
            change the world and bring us a better tomorrow. It goes beyond
            developing one{"'"}s knowledge and sharpening his skills as it paves
            way for progress of a nation and its generations to come. 
            </p>

            <p
              style={{
                color: darkBlueColor,
                fontSize: 16,
                fontFamily: "Rubik, sans-serif",
                textAlign: "justify",
                paddingLeft: 30,
                paddingRight: 30
              }}
            >
              In the 18 years of educational journey the ACE has set up some of the best
            degree colleges and nurtured the essence of growth in education. The
            Aishwarya College of Education, Pali was the first venture of the
            foundation in the league of the best colleges for BCA, BBA, BCOM,
            BSC (BIO), BSC (MATHS), and BA in Rajasthan. The College has earned
            respect of being the most reputed educational group in north India
            with the establishment of some of the best colleges in Rajasthan,
            prime focus being the holistic approach and overall development of
            its students
          </p>
        </GridItem>

        <GridItem xs={12} sm={12} md={6} lg={6} style={{ marginTop: 25 }}>
          <img width={'80%'} height={350} src={logo} style={{ borderRadius: 10 }}/>
        </GridItem> 
      </GridContainer>

      <GridContainer
        justify="center"
        style={{ paddingLeft: 10, paddingRight: 10, marginTop: 20, paddingBottom: 20, backgroundColor: darkBlueColor }}
      >
        <GridItem xs={12} sm={12} md={6} lg={6} style={{ marginTop: 70 }}>
          <img width={'85%'} height={650} src={directorImage} style={{ borderRadius: 10 }} />
        </GridItem>

        <GridItem xs={12} sm={12} md={6} lg={6}>
          <h2
            style={{
              fontSize: "30px",
              fontFamily: "Rubik, sans-serif",
              fontWeight: "bold",
              color: "white",
              marginBottom: 40,
            }}
          >
            Director’s Message
          </h2>
          <p
            style={{
              color: 'white',
              fontSize: 16,
              fontFamily: "Rubik, sans-serif",
              textAlign: "justify",
              paddingLeft: 30,
              paddingRight: 30
            }}
          >
            Dear students, faculty members, and guests,
          </p>

          <p
            style={{
              color: 'white',
              fontSize: 16,
              fontFamily: "Rubik, sans-serif",
              textAlign: "justify",
              paddingLeft: 30,
              paddingRight: 30
            }}
          >
            It is with great pleasure that I welcome you all to our college today.
          </p>

          <p
            style={{
              color: 'white',
              fontSize: 16,
              fontFamily: "Rubik, sans-serif",
              textAlign: "justify",
              paddingLeft: 30,
              paddingRight: 30
            }}
          >
            As the director of this esteemed institution, I am proud to say that we are committed to providing our students with the best education, facilities, and resources to help them achieve their goals and reach their full potential.
          </p>


          <p
            style={{
              color: 'white',
              fontSize: 16,
              fontFamily: "Rubik, sans-serif",
              textAlign: "justify",
              paddingLeft: 30,
              paddingRight: 30
            }}
          >
            Our faculty members are some of the best in the industry, and they are dedicated to providing our students with hands-on experience and knowledge that will prepare them for the challenges of the real world.
          </p>


          <p
            style={{
              color: 'white',
              fontSize: 16,
              fontFamily: "Rubik, sans-serif",
              textAlign: "justify",
              paddingLeft: 30,
              paddingRight: 30
            }}
          >
            We believe in providing a well-rounded education that not only focuses on academic excellence but also encourages our students to explore their interests and passions.
          </p>


          <p
            style={{
              color: 'white',
              fontSize: 16,
              fontFamily: "Rubik, sans-serif",
              textAlign: "justify",
              paddingLeft: 30,
              paddingRight: 30
            }}
          >
            We also take great pride in our state-of-the-art facilities that are designed to provide our students with a safe, comfortable, and conducive learning environment.
          </p>
          <p
            style={{
              color: 'white',
              fontSize: 16,
              fontFamily: "Rubik, sans-serif",
              textAlign: "justify",
              paddingLeft: 30,
              paddingRight: 30
            }}
          >
            As the world continues to evolve, we understand the importance of staying up-to-date with the latest technologies and trends. That is why we are constantly investing in our infrastructure and updating our curriculum to ensure that our students are equipped with the skills and knowledge that are in demand in the industry.
          </p>

          <p
            style={{
              color: 'white',
              fontSize: 16,
              fontFamily: "Rubik, sans-serif",
              textAlign: "justify",
              paddingLeft: 30,
              paddingRight: 30
            }}
          >
            As we embark on a new academic year, I urge all our students to make the most of this opportunity and take advantage of all the resources and facilities that are available to them.

          </p>

          <p
            style={{
              color: 'white',
              fontSize: 16,
              fontFamily: "Rubik, sans-serif",
              textAlign: "justify",
              paddingLeft: 30,
              paddingRight: 30
            }}
          >
            
Together, let us work towards creating a better tomorrow, one where education and knowledge are valued and celebrated.
          </p>

          <p
            style={{
              color: 'white',
              fontSize: 16,
              fontFamily: "Rubik, sans-serif",
              textAlign: "justify",
              paddingLeft: 30,
              paddingRight: 30
            }}
          >
            Thank you
          </p>

          <p
            style={{
              color: 'white',
              fontSize: 20,
              fontFamily: "Rubik, sans-serif",
              fontWeight: 'bold',
              textAlign: "justify",
              paddingLeft: 30,
              paddingRight: 30,
              textAlign: 'right'
            }}
          >
            Tajveer Singh Rathore
          </p>

          <p
            style={{
              color: 'white',
              fontSize: 16,
              fontFamily: "Rubik, sans-serif",
              fontWeight: 'bold',
              textAlign: "justify",
              paddingLeft: 30,
              paddingRight: 30,
              textAlign: 'right'
            }}
          >
            (Managing Director)
          </p>
        </GridItem>
      </GridContainer>
    </div>
  );
}
