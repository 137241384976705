import React from "react";
import {
  alpha,
  ThemeProvider,
  withStyles,
  makeStyles,
//   createTheme,
} from '@material-ui/core/styles';
import { TextField } from "@material-ui/core";

// const theme = createTheme({
//     palette: {
//       primary: green,
//     },
//   });

export default function InputText(props) {
  return (
    // <TextField
    //   fullWidth
    //   size={'medium'}
    //   id="outlined-multiline-flexible"
    //   variant="outlined"
    //   {...props}
    // />

    <ThemeProvider >
      <TextField
        //   className={classes.margin}
        fullWidth
        size={"medium"}
        label="ThemeProvider"
        variant="outlined"
        id="mui-theme-provider-outlined-input"
        {...props}
      />
    </ThemeProvider>
  );
}
