import React, { useEffect } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { darkBlueColor, grayTextColor } from "../../Utils/index";
import botanyLabImage from "assets/img/botanyLabImage.png";

const useStyles = makeStyles(styles);

export default function BotanyLab(props) {
  const classes = useStyles();
  const { ...rest } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header
        color="white"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <div
        className={classNames(classes.main)}
        style={{ marginTop: 110, backgroundColor: "white" }}
      >
        <div>
          <div className={classes.container} style={{ padding: 30 }}>
            <h2
              style={{
                fontSize: "30px",
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: darkBlueColor,
                marginBottom: 10,
              }}
            >
              Botany Lab
            </h2>

            <div style={{ marginBottom: 50, textAlign: 'center' }}>
              <img width={"50%"} height={"50%"} src={botanyLabImage} />
            </div>

            <div>
              <p
                style={{
                  color: grayTextColor,
                  fontSize: 16,
                  fontFamily: "Rubik, sans-serif",
                  textAlign: "justify",
                }}
              >
                Well spacious and well-ventilated Botany lab at ACE provides
                specimens, microscopes, permanent slides, latest method of
                physiological experiment, Genetics, section cutting of plants,
                demonstration dissection etc. Botany lab is well equipped for
                undergraduate students to perform various botany practical which
                are necessary for the study of the structure, properties, and
                biochemical processes of all forms of plant life, as well as
                plant classification, plant diseases, and the interactions of
                plants with their physical environment.
              </p>

              <p
                style={{
                  color: grayTextColor,
                  fontSize: 16,
                  fontFamily: "Rubik, sans-serif",
                  textAlign: "justify",
                }}
              >
                The lab activity also involves the field trips to study the
                flora related to Medicinal and economically important plants in
                the surrounding region. The field trips are really exciting
                experience for the students and they learn a lot about the
                subject matter. Above this the well qualified and experienced
                faculties are really an advantage for the students who at every
                step are there to help the student. The lab is well equipped
                with instruments like Microscope (Compound, Dissecting),
                Anemometer, Dry and wet bulbs thermometer, Rain Gauge etc.
              </p>

              <p
                style={{
                  color: grayTextColor,
                  fontSize: 16,
                  fontFamily: "Rubik, sans-serif",
                  textAlign: "justify",
                }}
              >
                Botany practicals covered during the session are Section
                cutting, Taxonomic studies, physiological & ecological
                experiment, Study of community, Biochemical analysis etc.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
