import React, { useEffect } from "react";
import classNames from "classnames";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { darkBlueColor, grayTextColor } from "../../Utils/index";
import ArtsDeptImage from "assets/img/ArtsDept.png";
import RaviGeoImage from "assets/img/RaviGeo.jpg";
import sandeepImage from "assets/img/sandeep.jpg";
import MADHAVGOPALImage from "assets/img/MADHAVGOPAL.jpg";
import mukeshJoshiImage from "assets/img/mukeshJoshi.jpg";
import ajaykallaImage from "assets/img/ajaykalla.jpg";

const useStyles = makeStyles(styles);

export default function Arts(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const imageStyle = {
    // borderRadius: 20
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header
        color="white"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <div
        className={classNames(classes.main)}
        style={{ marginTop: 110, backgroundColor: "white" }}
      >
        <div>
          <div className={classes.container} style={{ padding: 30 }}>
            <h2
              style={{
                fontSize: "30px",
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: darkBlueColor,
              }}
            >
              Arts Department
            </h2>

            <div
              style={{ marginTop: 20, marginBottom: 10, textAlign: "center" }}
            >
              <img width={"50%"} height={"50%"} src={ArtsDeptImage} style={imageStyle}/>
            </div>

            <div>
              <h2
                style={{
                  fontSize: "30px",
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "bold",
                  color: darkBlueColor,
                  marginBottom: 20,
                  textAlign: "center",
                }}
              >
                Welcome to the Department of Arts, ACE-Pali
              </h2>

              <p
                style={{
                  color: grayTextColor,
                  fontSize: 16,
                  fontFamily: "Rubik, sans-serif",
                  textAlign: "justify",
                }}
              >
                The journey of the Department of Arts at ACE-Pali began in 2013.
                We feel proud to mention here that it has flourished ever since
                and has proved its worth. We work with an aim to inculcate
                quality education among the students by using innovative
                teaching-learning practices to explore the future prospects of
                our students. The department takes various initiatives to
                improve students’ caliber like remedial classes, mentorship
                programs, etc. It also offers an interactive and diverse range
                of lectures, seminars, workshops, etc.to promote a practical
                learning environment. In each of our programs, we work on
                multilevel approaches with a focus on professional development
                throughout. At ACE-Pali, the Faculty of Arts offers B.A. Course
                with following subjects:
              </p>

              <List
                component="nav"
                className={classes.root}
                aria-label="contacts"
              >
                <ListItem>
                  <ListItemIcon>
                    <StarIcon style={{ color: darkBlueColor }} />
                  </ListItemIcon>
                  <ListItemText
                    style={{ color: grayTextColor }}
                    primary="History"
                  />
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <StarIcon style={{ color: darkBlueColor }} />
                  </ListItemIcon>
                  <ListItemText
                    style={{ color: grayTextColor }}
                    primary="Political Science"
                  />
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <StarIcon style={{ color: darkBlueColor }} />
                  </ListItemIcon>
                  <ListItemText
                    style={{ color: grayTextColor }}
                    primary="Geography"
                  />
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <StarIcon style={{ color: darkBlueColor }} />
                  </ListItemIcon>
                  <ListItemText
                    style={{ color: grayTextColor }}
                    primary="English Literature"
                  />
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <StarIcon style={{ color: darkBlueColor }} />
                  </ListItemIcon>
                  <ListItemText
                    style={{ color: grayTextColor }}
                    primary="Hindi Literature"
                  />
                </ListItem>
              </List>

              <h2
                style={{
                  fontSize: 25,
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "bold",
                  color: darkBlueColor,
                  marginBottom: 40,
                  textAlign: "center",
                }}
              >
                Faculties
              </h2>

              <Grid
                container
                spacing={3}
                style={{
                  justifyContent: "space-around",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  style={{ textAlign: "center" }}
                >
                  <img width={250} height={250} src={mukeshJoshiImage} style={imageStyle} />
                  <h2
                    style={{
                      fontSize: 20,
                      fontFamily: "Rubik, sans-serif",
                      fontWeight: "bold",
                      color: darkBlueColor,
                      marginBottom: -20,
                    }}
                  >
                    Mukesh Joshi
                  </h2>

                  <h2
                    style={{
                      fontSize: 14,
                      fontFamily: "Rubik, sans-serif",
                      color: grayTextColor,
                    }}
                  >
                    (MA(History), SET, NET)
                  </h2>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  style={{ textAlign: "center" }}
                >
                  <img width={250} height={250} src={RaviGeoImage} style={imageStyle} />
                  <h2
                    style={{
                      fontSize: 20,
                      fontFamily: "Rubik, sans-serif",
                      fontWeight: "bold",
                      color: darkBlueColor,
                      marginBottom: -20,
                    }}
                  >
                    Ravi Chouhan
                  </h2>

                  <h2
                    style={{
                      fontSize: 14,
                      fontFamily: "Rubik, sans-serif",
                      color: grayTextColor,
                    }}
                  >
                    (MA (Geography))
                  </h2>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  style={{ textAlign: "center" }}
                >
                  <img width={250} height={250} src={MADHAVGOPALImage} style={imageStyle} />
                  <h2
                    style={{
                      fontSize: 20,
                      fontFamily: "Rubik, sans-serif",
                      fontWeight: "bold",
                      color: darkBlueColor,
                      marginBottom: -20,
                    }}
                  >
                    Madhav Gopal
                  </h2>

                  <h2
                    style={{
                      fontSize: 14,
                      fontFamily: "Rubik, sans-serif",
                      color: grayTextColor,
                    }}
                  >
                    (MA(English), MSW)
                  </h2>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={3}
                style={{
                  justifyContent: "space-around",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  style={{ textAlign: "center" }}
                >
                  <img width={250} height={250} src={sandeepImage} style={imageStyle} />
                  <h2
                    style={{
                      fontSize: 20,
                      fontFamily: "Rubik, sans-serif",
                      fontWeight: "bold",
                      color: darkBlueColor,
                      marginBottom: -20,
                    }}
                  >
                    Sandeep Vaishnav
                  </h2>

                  <h2
                    style={{
                      fontSize: 14,
                      fontFamily: "Rubik, sans-serif",
                      color: grayTextColor,
                    }}
                  >
                    (MA(Hindi), NET)
                  </h2>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  style={{ textAlign: "center" }}
                >
                  <img width={250} height={250} src={ajaykallaImage} style={imageStyle} />
                  <h2
                    style={{
                      fontSize: 20,
                      fontFamily: "Rubik, sans-serif",
                      fontWeight: "bold",
                      color: darkBlueColor,
                      marginBottom: -20,
                    }}
                  >
                    Ajay Kalla
                  </h2>

                  <h2
                    style={{
                      fontSize: 14,
                      fontFamily: "Rubik, sans-serif",
                      color: grayTextColor,
                    }}
                  >
                    (MA (Pol. Science))
                  </h2>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
