import React, { useEffect } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { darkBlueColor, grayTextColor } from "../Utils/index";
import image1 from "assets/img/jnvu.jpg";

const useStyles = makeStyles(styles);

export default function Affiliations(props) {
  const classes = useStyles();
  const { ...rest } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header
        color="white"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <div
        className={classNames(classes.main)}
        style={{ marginTop: 110, backgroundColor: "white" }}
      >
        <div>
          <div className={classes.container} style={{ padding: 30 }}>
            <h2
              style={{
                fontSize: "30px",
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: darkBlueColor,
                marginBottom: 10,
              }}
            >
              Affiliations
            </h2>

            <div
              style={{ marginBottom: 10, textAlign: "center" }}
            >
              <img width={"50%"} height={"50%"} src={image1} />

              <h5
                style={{
                  marginTop: 10,
                  fontSize: "30px",
                  fontWeight: "bold",
                  fontFamily: "Rubik, sans-serif",
                  color: darkBlueColor,
                  marginBottom: 30
                }}
              >
                Jai Narain Vyas University(JNVU), Jodhpur
              </h5>
            </div>

            <div>
              <p
                style={{
                  color: grayTextColor,
                  fontSize: 16,
                  fontFamily: "Rubik, sans-serif",
                  textAlign: "justify",
                }}
              >
                Dr. S. Radhakrishnan, the President of India inaugurated the
                than University of Jodhpur on 24th August, 1962. In his
                inaugural address the Hon&#39;ble President revered Prof. Radha
                Krishnan desired and defined the goals and objective of the then
                Jodhpur University now J.N.V.U. That the young people, who will
                be educated in this university , will go forward not as
                prisoners of the past, but as pilgrims of the future; will
                develop in them a spirit of dedication, a spirit of
                renunciation, a spirit of whole-hearted, single- minded
                concentration on the advancement of the knowledge, whether in
                science or humanities.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
