import React from "react";
import Header from "../components/Header/Header";
import HeaderLinks from "../components/Header/HeaderLinks";
import classNames from "classnames";
import { Button, List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { darkBlueColor, grayTextColor, whiteColor } from "../Utils/index";
import Footer from "components/Footer/Footer";
import { useHistory } from "react-router";

const useStyles = makeStyles(styles);

export default function PageNotFound(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const history = useHistory();

  return (
    <div>
        <Header
            color="white"
            rightLinks={<HeaderLinks />}
            fixed
            changeColorOnScroll={{
            height: 200,
            color: "white",
            }}
            {...rest}
        />
        <div
            className={classNames(classes.main)}
            style={{ marginTop: 110, backgroundColor: "white" }}
        >
            <div>
                <div className={classes.container} style={{ padding: 30, textAlign: 'center' }}>
                    <h2
                    style={{
                        fontSize: "35px",
                        fontFamily: "Rubik, sans-serif",
                        fontWeight: "bold",
                        color: darkBlueColor,
                        marginBottom: 20,
                        marginTop: 50
                    }}
                    >
                       Sorry, page not found!
                    </h2>
                    <h2
                    style={{
                        fontSize: 180,
                        fontFamily: "Rubik, sans-serif",
                        fontWeight: "bold",
                        color: darkBlueColor,
                        marginBottom: 20,   
                    }}
                    >
                       404
                    </h2>
                    <Typography 
                        style={{ color: darkBlueColor }} >Sorry, we couldn’t find the page you’re looking for.</Typography>
                    <Typography 
                        style={{ color: darkBlueColor }} >Perhaps you’ve mistyped the URL? Be sure to check your spelling.</Typography>
                    <Button 
                        onClick={()=> history.push('/')}
                        style={{ backgroundColor: darkBlueColor, color: whiteColor, marginBottom: 50, marginTop: 30 }} variant="contained">Go To Home</Button>
                </div>
            </div>
        </div>

      <Footer />
    </div>
  )
}