import React, { useEffect } from "react";
import classNames from "classnames";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { darkBlueColor, grayTextColor } from "../../Utils/index";
import CsDeptImage from "assets/img/CsDept.png";
import NareshPurohitImage from "assets/img/NareshPurohit.jpg";
import sureshSutharImage from "assets/img/sureshSuthar.jpg";
import BhomSinghRathoreImage from "assets/img/BhomSinghRathore.jpg";
import GajendraJingarImage from "assets/img/GajendraJingar.jpeg";


const useStyles = makeStyles(styles);

export default function ComputerScience(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const imageStyle = {
    // borderRadius: 20
  }
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header
        color="white"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <div
        className={classNames(classes.main)}
        style={{ marginTop: 110, backgroundColor: "white" }}
      >
        <div>
          <div className={classes.container} style={{ padding: 30 }}>
            <h2
              style={{
                fontSize: "30px",
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: darkBlueColor,
              }}
            >
              Computer Science Department
            </h2>

            <div
              style={{ marginTop: 20, marginBottom: 10, textAlign: "center" }}
            >
              <img width={"50%"} height={"50%"} src={CsDeptImage} style={imageStyle} />
            </div>

            <div>
              <h2
                style={{
                  fontSize: "30px",
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "bold",
                  color: darkBlueColor,
                  marginBottom: 20,
                  textAlign: "center",
                }}
              >
                Welcome to the Department of Computer Science, ACE-Pali
              </h2>

              <p
                style={{
                  color: grayTextColor,
                  fontSize: 16,
                  fontFamily: "Rubik, sans-serif",
                  textAlign: "justify",
                }}
              >
                The Department of Computer Science at ACE-Pali was started in
                2010 offers BCA with a vision to provide a place for Innovation,
                Scientific Discovery and New Technology to evolve as a Centre of
                Excellence for research and learning, integrating Computer and
                Information Sciences. The department prepares over 100 graduates
                (UG) every year to become leaders in diverse fields such as
                academia, industry and government.
              </p>

              <List
                component="nav"
                className={classes.root}
                aria-label="contacts"
              >
                <ListItem>
                  <ListItemIcon>
                    <StarIcon style={{ color: darkBlueColor }} />
                  </ListItemIcon>
                  <ListItemText
                  style={{ color: grayTextColor }} 
                    primary="BCA program focus on preparing the students for wide range of IT careers and
Research equipping them with unique experience - moulding tomorrow's technocrats
with high caliber."
                  />
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <StarIcon style={{ color: darkBlueColor }} />
                  </ListItemIcon>
                  <ListItemText
                  style={{ color: grayTextColor }} 
                    primary="Our Department Provides Skill based training programmes to the students in association
with various MNC."
                  />
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <StarIcon style={{ color: darkBlueColor }} />
                  </ListItemIcon>
                  <ListItemText
                  style={{ color: grayTextColor }}
                    primary="Collaborated with many Industry institute for hands on Training and Internship for the
students during course period."
                  />
                </ListItem>
              </List>

              <h2
                style={{
                  fontSize: 25,
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "bold",
                  color: darkBlueColor,
                  marginBottom: 40,
                  textAlign: "center",
                }}
              >
                Faculties
              </h2>

              <Grid
                container
                spacing={3}
                style={{
                  justifyContent: "space-around",
                }}
              >
                {/* <Grid item xs={12} sm={12} md={3} lg={3} style={{textAlign: 'center'}}>
                  <img width={250} height={250} src={NareshPurohitImage} />
                  <h2
                    style={{
                      fontSize: 20,
                      fontFamily: "Rubik, sans-serif",
                      fontWeight: "bold",
                      color: darkBlueColor,
                      marginBottom: -20,
                    }}
                  >
                    Naresh Purohit
                  </h2>

                  <h2
                    style={{
                      fontSize: 14,
                      fontFamily: "Rubik, sans-serif",
                      color: grayTextColor,
                    }}
                  >
                    (Ph.D.*, MTech, BTech, CCNA)
                  </h2>
                </Grid> */}
                <Grid item xs={12} sm={12} md={3} lg={3} style={{textAlign: 'center'}}>
                  <img width={250} height={250} src={sureshSutharImage} style={imageStyle} />
                  <h2
                    style={{
                      fontSize: 20,
                      fontFamily: "Rubik, sans-serif",
                      fontWeight: "bold",
                      color: darkBlueColor,
                      marginBottom: -20,
                    }}
                  >
                    Suresh Suthar
                  </h2>

                  <h2
                    style={{
                      fontSize: 14,
                      fontFamily: "Rubik, sans-serif",
                      color: grayTextColor,
                    }}
                  >
                    (MCA, CCNA, CCNE)
                  </h2>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3} style={{textAlign: 'center'}}>
                  <img
                    width={250}
                    height={250}
                    src={BhomSinghRathoreImage}
                    style={imageStyle}
                  />
                  <h2
                    style={{
                      fontSize: 20,
                      fontFamily: "Rubik, sans-serif",
                      fontWeight: "bold",
                      color: darkBlueColor,
                      marginBottom: -20,
                    }}
                  >
                    Bhom Singh Rathore
                  </h2>

                  <h2
                    style={{
                      fontSize: 14,
                      fontFamily: "Rubik, sans-serif",
                      color: grayTextColor,
                    }}
                  >
                    (MCA, BCA)
                  </h2>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3} style={{textAlign: 'center'}}>
                  <img width={250} height={250} src={GajendraJingarImage} />
                  <h2
                    style={{
                      fontSize: 20,
                      fontFamily: "Rubik, sans-serif",
                      fontWeight: "bold",
                      color: darkBlueColor,
                      marginBottom: -20,
                    }}
                  >
                    Gajendra Jingar
                  </h2>

                  <h2
                    style={{
                      fontSize: 14,
                      fontFamily: "Rubik, sans-serif",
                      color: grayTextColor,
                    }}
                  >
                    (BCA, MCA)
                  </h2>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}