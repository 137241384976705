import React, { useEffect } from "react";
import classNames from "classnames";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { darkBlueColor, grayTextColor } from "../../Utils/index";
import ScienceDeptImage from "assets/img/ScienceDept.png";
import saritabohraBioImage from "assets/img/saritabohraBio.jpg";
import YudiImage from "assets/img/Yudi.jpg";
import amitChemImage from "assets/img/amitChem.jpg";
import aasthaImage from "assets/img/AasthaArora.jpeg";
import ChandraprakashImage from "assets/img/Chandraprakash.jpeg";
import PratibhaRajpurohitImage from "assets/img/PratibhaRajpurohit.jpeg";

const useStyles = makeStyles(styles);

export default function Science(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const imageStyle = {
    // borderRadius: 20
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header
        color="white"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <div
        className={classNames(classes.main)}
        style={{ marginTop: 110, backgroundColor: "white" }}
      >
        <div>
          <div className={classes.container} style={{ padding: 30 }}>
            <h2
              style={{
                fontSize: "30px",
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: darkBlueColor,
              }}
            >
              Science Department
            </h2>

            <div
              style={{ marginTop: 20, marginBottom: 10, textAlign: "center" }}
            >
              <img width={"50%"} height={"50%"} src={ScienceDeptImage} style={imageStyle} />
            </div>

            <div>
              <h2
                style={{
                  fontSize: "30px",
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "bold",
                  color: darkBlueColor,
                  marginBottom: 20,
                  textAlign: "center",
                }}
              >
                Welcome to the Department of Science, ACE-Pali
              </h2>

              <p
                style={{
                  color: grayTextColor,
                  fontSize: 16,
                  fontFamily: "Rubik, sans-serif",
                  textAlign: "justify",
                }}
              >
                The Department of Science at ACE-Pali offers BSc
                (Physics-Chemistry-Mathematics) and BSc
                (Chemistry-Botany-Zoology) separately. Department has taken
                every effort to introduce challenging, innovative and relevant
                courses which offer professional growth for students. The
                undergraduate program focuses on the core areas of science which
                serve as a conduit for research and career options after
                graduation. Conferences, seminars, workshops, guest lectures to
                support classroom teaching are a regular feature of the
                activities of the Department and are avenues for both faculty
                and students to expand their academic networks and share new
                knowledge.
              </p>

              <h2
                style={{
                  fontSize: 25,
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "bold",
                  color: darkBlueColor,
                  marginBottom: 40,
                  textAlign: "center",
                }}
              >
                Faculties
              </h2>

              <Grid
                container
                spacing={3}
                style={{
                  justifyContent: "space-around",
                }}
              >
                <Grid item xs={12} sm={12} md={3} lg={3} style={{textAlign: 'center'}}>
                  <img width={250} height={250} style={imageStyle} src={amitChemImage} />
                  <h2
                    style={{
                      fontSize: 20,
                      fontFamily: "Rubik, sans-serif",
                      fontWeight: "bold",
                      color: darkBlueColor,
                      marginBottom: -20,
                    }}
                  >
                    Amit Pindiyar
                  </h2>

                  <h2
                    style={{
                      fontSize: 14,
                      fontFamily: "Rubik, sans-serif",
                      color: grayTextColor,
                    }}
                  >
                    (Ph.D.*, M.Sc.- Chemistry)
                  </h2>
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3} style={{textAlign: 'center'}}>
                  <img width={250} height={250} style={imageStyle} src={saritabohraBioImage} />
                  <h2
                    style={{
                      fontSize: 20,
                      fontFamily: "Rubik, sans-serif",
                      fontWeight: "bold",
                      color: darkBlueColor,
                      marginBottom: -20,
                    }}
                  >
                    Dr. Sarita Purohit
                  </h2>

                  <h2
                    style={{
                      fontSize: 14,
                      fontFamily: "Rubik, sans-serif",
                      color: grayTextColor,
                    }}
                  >
                    (Ph.D. Botany)
                  </h2>
                </Grid>
                
                <Grid item xs={12} sm={12} md={3} lg={3} style={{textAlign: 'center'}}>
                  <img
                    width={250}
                    height={250}
                    src={YudiImage}
                    style={imageStyle}
                  />
                  <h2
                    style={{
                      fontSize: 20,
                      fontFamily: "Rubik, sans-serif",
                      fontWeight: "bold",
                      color: darkBlueColor,
                      marginBottom: -20,
                    }}
                  >
                    Yudhishthir Singh Chawara
                  </h2>

                  <h2
                    style={{
                      fontSize: 14,
                      fontFamily: "Rubik, sans-serif",
                      color: grayTextColor,
                    }}
                  >
                    (MSc. – Physics)
                  </h2>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={3}
                style={{
                  justifyContent: "space-around",
                }}
              >
                <Grid item xs={12} sm={12} md={3} lg={3} style={{textAlign: 'center'}}>
                  <img width={250} height={250} style={imageStyle} src={aasthaImage} />
                  <h2
                    style={{
                      fontSize: 20,
                      fontFamily: "Rubik, sans-serif",
                      fontWeight: "bold",
                      color: darkBlueColor,
                      marginBottom: -20,
                    }}
                  >
                    Aastha Arora
                  </h2>

                  <h2
                    style={{
                      fontSize: 14,
                      fontFamily: "Rubik, sans-serif",
                      color: grayTextColor,
                    }}
                  >
                    (BSC, Msc. (chemistry), PhD*)
                  </h2>
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3} style={{textAlign: 'center'}}>
                  <img width={250} height={250} style={imageStyle} src={ChandraprakashImage} />
                  <h2
                    style={{
                      fontSize: 20,
                      fontFamily: "Rubik, sans-serif",
                      fontWeight: "bold",
                      color: darkBlueColor,
                      marginBottom: -20,
                    }}
                  >
                    Chandraprakash
                  </h2>

                  <h2
                    style={{
                      fontSize: 14,
                      fontFamily: "Rubik, sans-serif",
                      color: grayTextColor,
                    }}
                  >
                    (B.SC , M.SC)
                  </h2>
                </Grid>
                
                <Grid item xs={12} sm={12} md={3} lg={3} style={{textAlign: 'center'}}>
                  <img width={250} height={250} style={imageStyle} src={PratibhaRajpurohitImage} />
                  <h2
                    style={{
                      fontSize: 20,
                      fontFamily: "Rubik, sans-serif",
                      fontWeight: "bold",
                      color: darkBlueColor,
                      marginBottom: -20,
                    }}
                  >
                    Pratibha Rajpurohit
                  </h2>

                  <h2
                    style={{
                      fontSize: 14,
                      fontFamily: "Rubik, sans-serif",
                      color: grayTextColor,
                    }}
                  >
                    (B.SC , M.SC)
                  </h2>
                </Grid>

              </Grid>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
