import React, { useEffect, useState } from "react";
import classNames from "classnames";
import {
  Grid,
  Button,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogContent,
  Slide
} from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { makeStyles } from "@material-ui/core/styles";
import { darkBlueColor, grayTextColor, apiUrl } from "../../Utils/index";
import InputText from "components/InputText";

const axios = require("axios");

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AdmissionEnquiry(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [values, setValues] = useState({
    name: "",
    mobile: "",
    email: "",
    course: "",
    city: "",
    state: "",
  });
  let [isSubmit, setSubmit] = useState(false);
  const [onSubmit, setOnSubmit] = useState(false);
  const [open, setOpen] = useState(false);
  const [successModalStatus, setSuccessModalStatus] = useState(false);
  const [errorModalStatus, setErrorModalStatus] = useState(false);
  const [invaildEmailError, setInvaildEmailError] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if(props && props?.courseName !== ""){
      setValues({ ...values, course: props?.courseName });
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const validate = () => {
    setSubmit(true);
    let i = (e) => values[e] === "";
    let require = ["name", "mobile", "course", "city", "state"];
    let error = require.map((e) => i(e)).includes(true);

    if (error) return null;
    else onSubmitContactForm();
  };

  const ValidateEmail = () => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (values.email.match(mailformat)) {
      onSubmitContactForm();
      setInvaildEmailError(false);
    } else {
      setInvaildEmailError(true);
    }
  };

  const onSubmitContactForm = () => {
    setOnSubmit(true);
    setOpen(true);

    axios({
      method: "POST",
      url: apiUrl + "/admissionenquiry",
      headers: {
        "Content-Type": "application/json",
      },
      data: values,
    })
      .then((res) => {
        setOpen(false);
        props?.handleCloseModal();
        setSuccessModalStatus(true);
        setOnSubmit(false);
        setValues({
          name: "",
          mobile: "",
          email: "",
          course: "",
          city: "",
          state: "",
        });
      })
      .catch((error) => {
        setOpen(false);
        props?.handleCloseModal();
        setErrorModalStatus(true);
        setOnSubmit(false);
      });
  };

  let courseData = [
    { label: "BCA", value: "bca" },
    { label: "BA", value: "ba" },
    { label: "B.Com", value: "b.com" },
    { label: "BSC (PCM-CBZ)", value: "bsc" },
  ];

  const handleChange = (e) => {
    setValues({ ...values, course: e.target.value });
  };

  const handleClose = () => {
    setSuccessModalStatus(false);
  };

  const handleErrorClose = () => {
    setErrorModalStatus(false);
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={successModalStatus}
      >
        <div
          style={{
            width: '100%',
            paddingTop: 20,
            paddingBottom: 20,
            paddingLeft: 20,
            paddingRight: 20,
            textAlign: "center",
          }}
        >
          <CheckCircleOutlineIcon style={{ fontSize: 100, color: "green" }} />
          <p
            style={{
              fontSize: 26,
              fontFamily: "Rubik, sans-serif",
              color: "green",
              fontWeight: "bold",
              marginTop: 10,
            }}
          >
            Success
          </p>

          <p
            style={{
              fontSize: 16,
              fontFamily: "Rubik, sans-serif",
              color: grayTextColor,
              marginTop: 20,
            }}
          >
            Your enquiry submitted successfully!
          </p>
          <p
            style={{
              fontSize: 16,
              fontFamily: "Rubik, sans-serif",
              color: grayTextColor,
              marginTop: -10,
            }}
          >
            Thank you!
          </p>
        </div>
      </Dialog>

      <Dialog
        onClose={handleErrorClose}
        aria-labelledby="customized-dialog-title"
        open={errorModalStatus}
      >
        <div
          style={{
            width: '100%',
            paddingTop: 20,
            paddingBottom: 20,
            paddingLeft: 20,
            paddingRight: 20,
            textAlign: "center",
          }}
        >
          <HighlightOffIcon style={{ fontSize: 100, color: "red" }} />
          <p
            style={{
              fontSize: 26,
              fontFamily: "Rubik, sans-serif",
              color: "red",
              fontWeight: "bold",
              marginTop: 10,
            }}
          >
            Error
          </p>

          <p
            style={{
              fontSize: 16,
              fontFamily: "Rubik, sans-serif",
              color: grayTextColor,
              marginTop: 20,
            }}
          >
            Something went wrong
          </p>

          <p
            style={{
              fontSize: 16,
              fontFamily: "Rubik, sans-serif",
              color: grayTextColor,
              marginTop: -10
            }}
          >
            Please try again later!
          </p>
        </div>
      </Dialog>

      <Dialog
        open={props?.openModal}
        onClose={props?.handleCloseModal}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent style={{ backgroundColor: 'white'  }}>
          <div> 
            <Backdrop className={classes.backdrop} open={open}>
              <CircularProgress color="inherit" />
            </Backdrop>

            <div className={classes.container}>
              <h2
                style={{
                  fontSize: "30px",
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "bold",
                  color: darkBlueColor,
                  marginBottom: 20,
                  marginTop: -10,
                  textAlign: "center",
                }}
              >
                Admission Enquiry
              </h2>
              <Grid
                container
                spacing={2}
                style={{ marginBottom: 10 }}
              >
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <InputText
                    label="Full Name"
                    type="text"
                    name="name"
                    value={values.name}
                    error={isSubmit && !values.name}
                    helperText={
                      isSubmit && !values.name ? "This field is required!" : ""
                    }
                    onChange={handleInputChange}
                  />
                </Grid>
              
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <InputText
                    label="Mobile Number"
                    type="number"
                    name="mobile"
                    value={values.mobile}
                    max="10"
                    min="10"
                    error={isSubmit && !values.mobile}
                    helperText={
                      isSubmit && !values.mobile
                        ? "This field is required!"
                        : ""
                    }
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                style={{ marginBottom: 10, justifyContent: "center" }}
              >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <InputText
                    label="Email Address"
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleInputChange}
                  />
                  {invaildEmailError && (
                    <span
                      style={{ color: "red", fontSize: 12, marginLeft: 12 }}
                    >
                      Invaild email address!
                    </span>
                  )}
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                style={{ marginBottom: 10, justifyContent: "center" }}
              >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Course
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={values.course}
                      onChange={handleChange}
                      label="Course"
                      style={{ textAlign: "left" }}
                      error={isSubmit && !values.course}
                      helperText={
                        isSubmit && !values.course
                          ? "This field is required!"
                          : ""
                      }
                    >
                      {courseData &&
                        courseData?.map((val, key) => {
                          return (
                            <MenuItem key={key} value={val.value}>
                              {val.label}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                  {isSubmit && !values.course ? (
                    <span
                      style={{ color: "red", fontSize: 12, marginLeft: 12 }}
                    >
                      This field is required!
                    </span>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                style={{ marginBottom: 10, justifyContent: "center" }}
              >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <InputText
                    label="City"
                    type="text"
                    name="city"
                    value={values.city}
                    error={isSubmit && !values.city}
                    helperText={
                      isSubmit && !values.city ? "This field is required!" : ""
                    }
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                style={{ marginBottom: 10, justifyContent: "center" }}
              >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <InputText
                    label="State"
                    type="text"
                    name="state"
                    value={values.state}
                    error={isSubmit && !values.state}
                    helperText={
                      isSubmit && !values.state ? "This field is required!" : ""
                    }
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                style={{ marginBottom: 10, justifyContent: "center" }}
              >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Button
                  fullWidth
                    variant="contained"
                    onClick={() => validate()}
                    disabled={onSubmit}
                    style={{
                      backgroundColor: darkBlueColor,
                      color: "white",
                      padding: 10
                    }}
                  >
                    {onSubmit ? "Submitting" : "Submit"}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
