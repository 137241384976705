import React, { useEffect } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { darkBlueColor, grayTextColor } from "../../Utils/index";
import IndustrialVisitImage from "assets/img/IndustrialVisit.png";

const useStyles = makeStyles(styles);

export default function IndustrialVisit(props) {
  const classes = useStyles();
  const { ...rest } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header
        color="white"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <div
        className={classNames(classes.main)}
        style={{ marginTop: 110, backgroundColor: "white" }}
      >
        <div>
          <div className={classes.container} style={{ padding: 30 }}>
            <h2
              style={{
                fontSize: "30px",
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: darkBlueColor,
                marginBottom: 10,
              }}
            >
              Industrial Visit
            </h2>

            <div style={{ marginBottom: 50, textAlign: 'center' }}>
              <img width={"50%"} height={"50%"} src={IndustrialVisitImage} />
            </div>

            <div>
              <p
                style={{
                  color: grayTextColor,
                  fontSize: 16,
                  fontFamily: "Rubik, sans-serif",
                  textAlign: "justify",
                }}
              >
                As a part of university program and an exposure towards the
                industry and corporate world students at ACE-Pali visits to
                industry in each academic year. The objectives of industrial
                visit is to provide students an insight regarding internal
                working of companies. We are well known for practical approach
                towards learning and development of students. Industrial visits
                focuses on cultivating the culture of observation,
                implementation and deals with real time issues, gain the
                experience & implement it in real time environment while
                developing projects.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}