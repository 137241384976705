import React, { useEffect } from "react";
import classNames from "classnames";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { darkBlueColor, grayTextColor } from "../Utils/index";

const useStyles = makeStyles(styles);

export default function AboutUs(props) {
  const classes = useStyles();
  const { ...rest } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header
        color="white"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <div
        className={classNames(classes.main)}
        style={{ marginTop: 110, backgroundColor: "white" }}
      >
        <div>
          <div className={classes.container} style={{ padding: 30 }}>
            <h2
              style={{
                fontSize: "30px",
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: darkBlueColor,
                marginBottom: 40,
              }}
            >
              About ACE
            </h2>

            <div>
              <p
                style={{
                  color: grayTextColor,
                  fontSize: 16,
                  fontFamily: "Rubik, sans-serif",
                  textAlign: "justify",
                }}
              >
                Aishwarya College of Education, Pali established in 2009 under
                the aegis of Gayatri Technical Education Trust achieves another
                milestone by entering into 11th year of its commitment towards
                betterment of education standards in W. Rajasthan. At ACE we
                explore and develop the arenas of students’ talent yet
                undiscovered and try to make them proficient in the field that
                they wish to explore. The team of highly qualified and
                academically profound professionals is dedicated towards
                cultivation of thought process that motivates students for
                self-development and excellence as we believe that we are our
                competitions ourselves and nobody other than us can defeat us.
                This fact is also highlighted by our own institutional growth
                and the goals set by us for ourselves.
              </p>

              <p
                style={{
                  color: grayTextColor,
                  fontSize: 16,
                  fontFamily: "Rubik, sans-serif",
                  textAlign: "justify",
                }}
              >
                At ACE-Pali we try to provide best facilities and environment
                that automatically leads to the generation of will full
                understanding and zest towards the academic and professional
                inclination of the students. The infrastructure and growth
                opportunities available to the students are incomparable as
                every individual here is treated as a professional and not
                merely a student and encouraged to implement positive attitude
                leading towards development of natural panache.
              </p>

              <h5
                style={{
                  marginTop: 30,
                  fontSize: 20,
                  fontWeight: "bold",
                  fontFamily: "Rubik, sans-serif",
                  color: darkBlueColor,
                }}
              >
                Why Choose Us
              </h5>
              <p
                style={{
                  color: grayTextColor,
                  fontSize: 16,
                  fontFamily: "Rubik, sans-serif",
                  textAlign: "justify",
                }}
              >
                ACE recognizes how important it is for students to balance the
                many responsibilities of their busy lives. We strive to make
                earning your degree as convenient as possible, while providing
                an affordable, quality education in a supportive environment.
                We’ve been helping working adults continue their education to
                advance their careers. Our programs make it possible for you to
                manage all of the different facets of your life while you
                achieve your educational goals.
              </p>

              <p
                style={{
                  color: grayTextColor,
                  fontSize: 16,
                  fontFamily: "Rubik, sans-serif",
                  textAlign: "justify",
                  marginBottom: 30,
                }}
              >
                ACE is a place where you will feel comfortable because you will
                be with classmates who share your real- world perspective and
                enrich your studies with their professional and life
                experiences.
              </p>

              <h5
                style={{
                  marginTop: 30,
                  fontSize: 16,
                  fontWeight: "bold",
                  fontFamily: "Rubik, sans-serif",
                  color: darkBlueColor,
                }}
              >
                Teaching Pedagogy
              </h5>
              <p
                style={{
                  color: grayTextColor,
                  fontSize: 16,
                  fontFamily: "Rubik, sans-serif",
                  textAlign: "justify",
                }}
              >
                The ultimate of education is to prepare students to achieve
                professional excellence In business world. Keeping in view , 
                the changing scenario, the faculty focuses on creativity,
                critical thinking and communication and facilities the students
                to unearth their latent talents by blending of various
                strategies which includes:
              </p>

              <List
                component="nav"
                className={classes.root}
                aria-label="contacts"
              >
                <ListItem>
                  <ListItemIcon>
                    <StarIcon style={{ color: darkBlueColor }} />
                  </ListItemIcon>
                  <ListItemText
                    style={{ color: grayTextColor }}
                    primary="Lectures :- Writing assignment and projects"
                  />
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <StarIcon style={{ color: darkBlueColor }} />
                  </ListItemIcon>
                  <ListItemText
                    style={{ color: grayTextColor }}
                    primary="Seminars and workshops :- Project training"
                  />
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <StarIcon style={{ color: darkBlueColor }} />
                  </ListItemIcon>
                  <ListItemText
                    style={{ color: grayTextColor }}
                    primary="Personality and communication skills development :- Management games"
                  />
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <StarIcon style={{ color: darkBlueColor }} />
                  </ListItemIcon>
                  <ListItemText
                    style={{ color: grayTextColor }}
                    primary="Brain storming session :- industrial visits"
                  />
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <StarIcon style={{ color: darkBlueColor }} />
                  </ListItemIcon>
                  <ListItemText
                    style={{ color: grayTextColor }}
                    primary="Case method :- Event management"
                  />
                </ListItem>
              </List>

              <h5
                style={{
                  marginTop: 30,
                  fontSize: 16,
                  fontWeight: "bold",
                  fontFamily: "Rubik, sans-serif",
                  color: darkBlueColor,
                }}
              >
                Mission
              </h5>
              <p
                style={{
                  color: grayTextColor,
                  fontSize: 16,
                  fontFamily: "Rubik, sans-serif",
                  textAlign: "justify",
                }}
              >
                To strive for quality education in keeping with the motto of the
                college, “Excellence in Education” and prepare young minds for
                imbibing knowledge, skills and sensitivity.
              </p>

              <h5
                style={{
                  marginTop: 30,
                  fontSize: 16,
                  fontWeight: "bold",
                  fontFamily: "Rubik, sans-serif",
                  color: darkBlueColor,
                }}
              >
                Vision
              </h5>
              <p
                style={{
                  color: grayTextColor,
                  fontSize: 16,
                  fontFamily: "Rubik, sans-serif",
                  textAlign: "justify",
                  marginBottom: 50,
                }}
              >
                To provide affordable quality education, while equipping
                students with knowledge and skills in their chosen stream,
                inculcate values, identify hidden talents, provide opportunities
                for students to realize their full potential and thus shape them
                into future leaders, entrepreneurs and above all good human
                beings.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
