import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  Button,
  Backdrop,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Header from "../../../components/Header/Header";
import HeaderLinks from "../../../components/Header/HeaderLinks";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { darkBlueColor, whiteColor, apiUrl } from "../../../Utils/index";
import Footer from "components/Footer/Footer";

const axios = require("axios");
const useStyles = makeStyles(styles);

export default function PreviousPaperCourseList(props) {
  const classes = useStyles();
  const history = useHistory();
  const { ...rest } = props;

  const [ppData, setPpData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    axios({
      method: "GET",
      url: apiUrl + "/previouspaper",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        let courseData = res.data.filter((tag, index, array) => array.findIndex(t => t.courseId == tag.courseId) == index);
        setPpData(courseData);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }, []);

  return (
    <div>
        <Header
            color="white"
            rightLinks={<HeaderLinks />}
            fixed
            changeColorOnScroll={{
            height: 200,
            color: "white",
            }}
            {...rest}
        />
        <div
            className={classNames(classes.main)}
            style={{ marginTop: 110, backgroundColor: "white" }}
        >
            <div>
                <div className={classes.container} style={{ padding: 30 }}>
                    <h2
                    style={{
                        fontSize: "30px",
                        fontFamily: "Rubik, sans-serif",
                        fontWeight: "bold",
                        color: darkBlueColor,
                        marginBottom: 40,
                    }}
                    >
                        Previous Paper
                    </h2>

                    {loading ? 
                        <div style={{ textAlign: 'center', marginBottom: 200, marginTop: 200 }}>
                            <CircularProgress /> 
                        </div>
                        :
                        <div>
                            <Grid
                                container
                                spacing={2}
                                style={{ textAlign: 'center', marginBottom: 20 }}
                            >
                                {ppData && ppData.length > 0 ? 
                                    ppData.map((value, index) => {
                                    return(
                                        <Grid key={index} item xs={12} sm={6} md={3} lg={3}>
                                            <Card
                                                onClick={()=> history.push('/previous-paper/' + (value.courseId).toLowerCase())}
                                                style={{
                                                    borderRadius: 10,
                                                    paddingLeft: 20,
                                                    padding: 30,
                                                    cursor: 'pointer',
                                                    backgroundColor: darkBlueColor,
                                                }}
                                            >
                                                <h2
                                                    style={{
                                                        fontSize: 26,
                                                        fontWeight: 'bold',
                                                        fontFamily: "Rubik, sans-serif",
                                                        color: whiteColor,
                                                    }}
                                                >
                                                {value.courseId}
                                                </h2>
                                            </Card>
                                        </Grid>
                                    )
                                }) :
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <h2
                                        style={{
                                            fontSize: "30px",
                                            fontFamily: "Rubik, sans-serif",
                                            fontWeight: "bold",
                                            color: darkBlueColor,
                                            marginTop: 100,
                                            marginBottom: 200,
                                        }}
                                        >
                                            No Data
                                    </h2>
                                </Grid>
                                } 
                            </Grid>
                        </div>
                    }
                </div>
            </div>
        </div>
      <Footer />
    </div>
  )
}