import React, { useEffect, useState } from "react";
import moment from "moment";
import classNames from "classnames";
import { Grid, Card, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { darkBlueColor, yelloColor, apiUrl, formatTime } from "../Utils/index";
import Pagination from "@mui/material/Pagination";

const axios = require("axios");

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#fff",
      borderColor: "#fff",
    },
    "& .MuiPaginationItem-root.Mui-selected": {
      backgroundColor: "#fff",
      color: "black",
    },
  },
}));

export default function AllNoticeBoard(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [noticeBoardData, setNoticeBoardData] = useState([]);
  const [totalNBData, setTotalNBData] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(4);

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    axios({
      method: "GET",
      url: apiUrl + "/noticeboard",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        setNoticeBoardData(res.data);
        setTotalNBData(res.data.length);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }, []);

  return (
    <div>
      <Header
        color="white"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <div
        className={classNames(classes.main)}
        style={{ marginTop: 110, backgroundColor: "white" }}
      >
        <div>
          <div
            className={classes.container}
            style={{
              padding: 30,
              backgroundColor: "#fff",
              paddingBottom: 100,
            }}
          >
            <h2
              style={{
                fontSize: "20px",
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: darkBlueColor,
                marginBottom: 20,
              }}
            >
              Notice & Events
            </h2>

            <Grid container spacing={3}>
              {noticeBoardData && noticeBoardData?.map((value, key)=> {
                return(
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <a
                        target="_blank"
                        href={value.attachmentId != "" ? apiUrl + "/download/" + value.attachmentId : null}
                        style={{
                          textDecoration: "none",
                          textDecorationColor: "none",
                          color: "black",
                        }}
                        class="button"
                        key={key}
                      >
                        <Card
                          style={{
                            backgroundColor: yelloColor,
                            padding: 10,
                            borderRadius: 10
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={4} sm={4} md={3} lg={3}>
                              <Card
                                style={{
                                  backgroundColor: darkBlueColor,
                                  borderRadius: 10,
                                  textAlign: 'center'
                                }}
                              >
                                {value?.hasOwnProperty("date") ?
                                  <>
                                    <h6 style={{ fontSize: 40, height: 50, color: "#fff", fontWeight: 'bold' }} >{moment(value.date).format("DD")}</h6>
                                    <h6 style={{ backgroundColor: '#fff', height: 1, color: "#fff" }} ></h6>
                                    <h6 style={{ fontSize: 18, color: "#fff" }} >{moment(value.date).format("MMM YYYY")}</h6>
                                  </>
                                :
                                  <>
                                    <h6 style={{ fontSize: 40, height: 50, color: "#fff", fontWeight: 'bold' }} >{moment.utc(value.createdAt).format('DD')}</h6>
                                    <h6 style={{ backgroundColor: '#fff', height: 1, color: "#fff" }} ></h6>
                                    <h6 style={{ fontSize: 18, color: "#fff" }} >{moment(value.createdAt).format("MMM YYYY")}</h6>
                                  </>
                                }
                                
                              </Card>
                            </Grid>

                            <Grid item xs={8} sm={8} md={9} lg={9}>
                              <h6 style={{ fontSize: 16, color: darkBlueColor, fontWeight: 'bold' }} >{value?.title}</h6>
                              <h6 style={{ fontSize: 12, color: darkBlueColor, }} >{value?.description}</h6>
                              {/* <h6 style={{ fontSize: 12, color: darkBlueColor, textTransform: 'none' }} >Created At: {moment.utc(value.createdAt).format('DD MMM YYYY')}</h6> */}
                            </Grid>
                          </Grid>
                        </Card>
                      </a>
                    </Grid>
                  )
                })
              }
            </Grid>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
