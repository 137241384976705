import React, { useEffect } from "react";
import classNames from "classnames";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { darkBlueColor, grayTextColor } from "../Utils/index";
import chairperson from "assets/img/chairperson.png";

const useStyles = makeStyles(styles);

export default function ChairmanMessage(props) {
  const classes = useStyles();
  const { ...rest } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header
        color="white"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <div
        className={classNames(classes.main)}
        style={{ marginTop: 110, backgroundColor: "white" }}
      >
        <div>
          <div className={classes.container} style={{ padding: 30 }}>
            <h2
              style={{
                fontSize: "30px",
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: darkBlueColor,
                marginBottom: 40,
              }}
            >
              Chairman’s Message
            </h2>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <div style={{ textAlign: "center", marginBottom: 40 }}>
                  <img
                    width={"100%"}
                    height={"100%"}
                    src={chairperson}
                    alt="image"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={9} lg={9}>
                <div>
                  <p
                    style={{
                      color: grayTextColor,
                      fontSize: 16,
                      fontFamily: "Rubik, sans-serif",
                      textAlign: "justify",
                    }}
                  >
                    It gives immense contentment to complete a long journey of
                    expedition the path traversed with great pains &amp;
                    pleasures has resulted in achieving the desired outcome of
                    consistent growth. College still is its nascent stage always
                    strives to keep pace with modern education and traditional
                    values. ACE fraternity would always be appreciative and
                    thankful to all those people who have been associated with
                    us during this voyage and contributed towards our success,
                    without whom these accomplishments would have been
                    completely impossible.
                  </p>

                  <p
                    style={{
                      color: grayTextColor,
                      fontSize: 16,
                      fontFamily: "Rubik, sans-serif",
                      textAlign: "justify",
                    }}
                  >
                    The underlying thought behind the mission have been to give
                    students a platform to nurture their creativity, academic
                    zeal and help them stretch their abilities to envision
                    future path. Keeping this aspect in mind and dogmatic
                    approach to deliver the best has prompted us at Aishwarya
                    College to create category of performers both academically
                    and in co-curricular activities. We hope that the students
                    associating with us to do for enhancing their all-round
                    skill set, to have a better and wide learning experience and
                    to deliver the output with positive attitude.
                  </p>

                  <p
                    style={{
                      color: grayTextColor,
                      fontSize: 16,
                      fontFamily: "Rubik, sans-serif",
                      textAlign: "justify",
                    }}
                  >
                    These thoughts have impelled our guideline behind allowing
                    the students to explore possibility of growth and
                    development by giving them whole hearted support in their
                    initiatives and educative pursuits.
                  </p>

                  <h5
                    style={{
                      marginTop: 30,
                      fontSize: 16,
                      fontWeight: "bold",
                      color: grayTextColor,
                      fontFamily: "Rubik, sans-serif",
                    }}
                  >
                    I on behalf of ACE fraternity welcome and wish for the
                    successful. Pleasurable learning experience for all the new
                    entrants to the family.
                  </h5>

                  <h5
                    style={{
                      marginTop: 50,
                      fontSize: 20,
                      fontWeight: "bold",
                      color: darkBlueColor,
                      marginBottom: -10,
                      fontFamily: "Rubik, sans-serif",
                      textAlign: 'right'
                    }}
                  >
                    Bhupendra Singh Rathore
                  </h5>

                  <h5
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                      color: grayTextColor,
                      fontFamily: "Rubik, sans-serif",
                      textAlign: 'right'
                    }}
                  >
                    (Chairman)
                  </h5>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
