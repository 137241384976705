import React, { useEffect, useState } from "react";
import classNames from "classnames";
import {
  Grid,
  Card,
  Button,
  Backdrop,
  CircularProgress,
  Dialog,
} from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email';
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { darkBlueColor, yelloColor, apiUrl } from "../Utils/index";
import InputText from "components/InputText";

const axios = require("axios");

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function ContactUs(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [values, setValues] = useState({
    name: "",
    email: "",
    message: "",
  });
  let [isSubmit, setSubmit] = useState(false);
  const [onSubmit, setOnSubmit] = useState(false);
  const [open, setOpen] = useState(false);
  const [successModalStatus, setSuccessModalStatus] = useState(false);
  const [errorModalStatus, setErrorModalStatus] = useState(false);
  const [invaildEmailError, setInvaildEmailError] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    setInvaildEmailError(false);
  };

  const validate = () => {
    setSubmit(true);
    let i = (e) => values[e] === "";
    let require = ["name", "email", "message"];
    let error = require.map((e) => i(e)).includes(true);

    if (error) return null;
    else ValidateEmail();
  };

  const ValidateEmail = () => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (values.email.match(mailformat)) {
      onSubmitContactForm();
      setInvaildEmailError(false);
    } else {
      setInvaildEmailError(true);
    }
  };

  const onSubmitContactForm = () => {
    setOnSubmit(true);
    setOpen(true);

    axios({
      method: "POST",
      url: apiUrl + "/contactenquiry",
      headers: {
        "Content-Type": "application/json",
      },
      data: values,
    })
      .then((res) => {
        setOpen(false);
        setSuccessModalStatus(true);
        setOnSubmit(false);
      })
      .catch((error) => {
        setOpen(false);
        setErrorModalStatus(true);
        setOnSubmit(false);
      });
  };

  const handleClose = () => {
    setSuccessModalStatus(false);
    window.location.reload();
  };

  const handleErrorClose = () => {
    setErrorModalStatus(false);
    window.location.reload();
  };

  return (
    <div>
      <Header
        color="white"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <div
        className={classNames(classes.main)}
        style={{ marginTop: 110, backgroundColor: "white" }}
      >
        <div>
          <Backdrop className={classes.backdrop} open={open}>
            <CircularProgress color="inherit" />
          </Backdrop>

          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={successModalStatus}
          >
            <div
              style={{
                paddingLeft: 120,
                paddingRight: 120,
                paddingTop: 20,
                paddingBottom: 20,
                textAlign: "center",
              }}
            >
              <CheckCircleOutlineIcon
                style={{ fontSize: 100, color: "green" }}
              />
              <p
                style={{
                  fontSize: 26,
                  color: "green",
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                Success
              </p>

              <p
                style={{
                  fontSize: 16,
                  color: "gray",
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                Your message sent successfully!
              </p>
              <p
                style={{
                  fontSize: 16,
                  color: "gray",
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                Thank you!
              </p>
            </div>
          </Dialog>

          <Dialog
            onClose={handleErrorClose}
            aria-labelledby="customized-dialog-title"
            open={errorModalStatus}
          >
            <div
              style={{
                paddingLeft: 120,
                paddingRight: 120,
                paddingTop: 20,
                paddingBottom: 20,
                textAlign: "center",
              }}
            >
              <HighlightOffIcon style={{ fontSize: 100, color: "red" }} />
              <p
                style={{
                  fontSize: 26,
                  color: "red",
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                Error
              </p>

              <p
                style={{
                  fontSize: 16,
                  color: "gray",
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                Something went wrong. Please try again!
              </p>
            </div>
          </Dialog>

          <div className={classes.container} style={{ padding: 30 }}>
            <h2
              style={{
                fontSize: "30px",
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: darkBlueColor,
                marginBottom: 50,
              }}
            >
              Contact Us
            </h2>

            <div>
              <Grid
                container
                spacing={3}
                style={{ textAlign: "center", marginBottom: 100 }}
              >
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Card
                    style={{
                      backgroundColor: darkBlueColor,
                      height: 220,
                      borderRadius: 30,
                    }}
                  >
                    <h2
                      style={{
                        fontSize: "30px",
                        fontFamily: "Rubik, sans-serif",
                        fontWeight: "bold",
                        color: yelloColor,
                      }}
                    >
                      Address
                    </h2>

                    <LocationOnIcon style={{ color: 'white', fontSize: 40 }} />

                    <p
                      style={{
                        fontSize: 16,
                        fontFamily: "Rubik, sans-serif",
                        color: "white",
                        fontWeight: 'bold',
                        marginBottom: 5,
                      }}
                    >
                      Naya Gaon Road
                    </p>
                    <p
                      style={{
                        fontSize: 16,
                        fontFamily: "Rubik, sans-serif",
                        color: "white",
                        fontWeight: 'bold',
                        marginBottom: 5,
                      }}
                    >
                      Pali-Rajasthan (306401)
                    </p>
                    {/* <p
                      style={{
                        fontSize: 16,
                        fontFamily: "Rubik, sans-serif",
                        color: "white",
                        fontWeight: 'bold',
                      }}
                    >
                      306401
                    </p> */}
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Card
                    style={{
                      backgroundColor: darkBlueColor,
                      height: 220,
                      borderRadius: 30,
                    }}
                  >
                    <h2
                      style={{
                        fontSize: "30px",
                        fontFamily: "Rubik, sans-serif",
                        fontWeight: "bold",
                        color: yelloColor,
                      }}
                    >
                      Phone
                    </h2>

                    <CallIcon style={{ color: 'white', fontSize: 40, marginBottom: 10 }} />
                    <p
                      style={{
                        fontSize: 16,
                        fontFamily: "Rubik, sans-serif",
                        color: "white",
                        fontWeight: 'bold',
                        marginBottom: 5,
                      }}
                    >
                      91666-44756
                    </p>
                    <p
                      style={{
                        fontSize: 16,
                        fontFamily: "Rubik, sans-serif",
                        color: "white",
                        fontWeight: 'bold',
                      }}
                    >
                      94149-77699
                    </p>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Card
                    style={{
                      backgroundColor: darkBlueColor,
                      height: 220,
                      borderRadius: 30,
                    }}
                  >
                    <h2
                      style={{
                        fontSize: "30px",
                        fontFamily: "Rubik, sans-serif",
                        fontWeight: "bold",
                        color: yelloColor,
                      }}
                    >
                      E-Mail
                    </h2>

                    <EmailIcon style={{ color: 'white', fontSize: 40, marginBottom: 10 }} />
                    <p
                      style={{
                        fontSize: 16,
                        fontFamily: "Rubik, sans-serif",
                        color: "white",
                        fontWeight: 'bold',
                        marginBottom: 5,
                      }}
                    >
                      acepalicollege@gmail.com
                    </p>
                    <p
                      style={{
                        fontSize: 16,
                        fontFamily: "Rubik, sans-serif",
                        color: "white",
                        fontWeight: 'bold',
                      }}
                    >
                      info@aishwaryacollegepali.edu.in
                    </p>
                  </Card>
                </Grid>
              </Grid>
            </div>
            <Grid container spacing={3} style={{ marginBottom: 50 }}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <h4
                  style={{
                    textAlign: "center",
                    marginBottom: 20,
                    fontWeight: "bold",
                    color: darkBlueColor,
                  }}
                >
                  Location
                </h4>

                <div class="mapouter">
                  <div class="gmap_canvas">
                    <iframe
                      style={{ width: "100%", height: 400 }}
                      class="gmap_iframe"
                      frameborder="0"
                      scrolling="no"
                      marginheight="0"
                      marginwidth="0"
                      src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Aishwarya college pali&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                    ></iframe>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <h4
                  style={{
                    textAlign: "center",
                    marginBottom: 20,
                    fontWeight: "bold",
                    color: darkBlueColor,
                  }}
                >
                  Send us message
                </h4>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <InputText
                      fullWidth
                      label="Full Name"
                      type="text"
                      name="name"
                      value={values.name}
                      error={isSubmit && !values.name}
                      helperText={
                        isSubmit && !values.name
                          ? "This field is required!"
                          : ""
                      }
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <InputText
                      fullWidth
                      label="Email Address"
                      type="email"
                      name="email"
                      value={values.email}
                      error={isSubmit && (!values.email || invaildEmailError)}
                      helperText={
                        isSubmit && !values.email
                          ? "This field is required!"
                          : ""
                      }
                      onChange={handleInputChange}
                    />
                    {invaildEmailError && (
                      <span
                        style={{ color: "red", fontSize: 12, marginLeft: 12 }}
                      >
                        Invaild email address!
                      </span>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <InputText
                      fullWidth
                      label="Message"
                      type="text"
                      name="message"
                      value={values.message}
                      error={isSubmit && !values.message}
                      helperText={
                        isSubmit && !values.message
                          ? "This field is required!"
                          : ""
                      }
                      onChange={handleInputChange}
                      multiline
                      rows={4}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    style={{ textAlign: "right" }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => validate()}
                      disabled={onSubmit}
                      style={{
                        backgroundColor: yelloColor,
                        color: darkBlueColor,
                      }}
                    >
                      {onSubmit ? "Sending" : "Send Message"}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
