import React, { useEffect } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { darkBlueColor, grayTextColor } from "../../Utils/index";
import digitalLabImage from "assets/img/digitalLabImage.png";

const useStyles = makeStyles(styles);

export default function DigitalLab(props) {
  const classes = useStyles();
  const { ...rest } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header
        color="white"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <div
        className={classNames(classes.main)}
        style={{ marginTop: 110, backgroundColor: "white" }}
      >
        <div>
          <div className={classes.container} style={{ padding: 30 }}>
            <h2
              style={{
                fontSize: "30px",
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: darkBlueColor,
                marginBottom: 10,
              }}
            >
              Digital Electronics Lab
            </h2>

            <div style={{ marginBottom: 50, textAlign: 'center' }}>
              <img width={"50%"} height={"50%"} src={digitalLabImage} />
            </div>

            <div>
              <p
                style={{
                  color: grayTextColor,
                  fontSize: 16,
                  fontFamily: "Rubik, sans-serif",
                  textAlign: "justify",
                }}
              >
                The objective of this laboratory is to link the theoretical
                concepts of different digital electronics circuits with
                practical feasibility by giving to the students a scope to learn
                digital circuits in a better way. This laboratory is useful for
                BCA-I-year students to understand the basic fundamentals of
                digital circuits.
              </p>

              <p
                style={{
                  color: grayTextColor,
                  fontSize: 16,
                  fontFamily: "Rubik, sans-serif",
                  textAlign: "justify",
                }}
              >
                The lab is well equipped with digital electronic components, so
                students can build their own circuit. The lab also comprises of
                digital trainer kits so as to facilitate verification of the
                results obtained through the fabricated circuits.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
