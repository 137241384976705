import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, Slide } from "@material-ui/core";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import ProductSection from "./Sections/ProductSection.js";
import TeamSection from "./Sections/TeamSection.js";
import WorkSection from "./Sections/WorkSection.js";
import SectionCarousel from "views/Components/Sections/SectionCarousel.js";
import popupImage from "assets/img/admission2425.jpeg";
import { useHistory } from "react-router-dom";
import AdmissionEnquiry from "views/Admission/AdmissionEnquiry.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function LandingPage(props) {
  const classes = useStyles();
  const history = useHistory();
  const { ...rest } = props;
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setOpen(true);
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const openEnquiryForm = () => {
    setOpen(false);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <Header
        color="white"
        routes={dashboardRoutes}
        // brand="Aishwarya College of Education, Pali"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <div style={{ marginTop: 74 }}>&nbsp;</div>
      <SectionCarousel />

      <div className={classNames(classes.main)}>
        <div
          className={classes.container}
          style={{ marginTop: -30, backgroundColor: "white" }}
        >
          <ProductSection />

          <Dialog
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            keepMounted
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <div
              style={{ cursor: "pointer" }}
              onClick={() => openEnquiryForm()}
            >
              <img width={'100%'} height={500} src={popupImage} />
            </div>
          </Dialog>

          <AdmissionEnquiry
            openModal={openModal}
            handleCloseModal={handleCloseModal}
          />

          <TeamSection />
          {/* <WorkSection /> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}
