import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Grid } from "@material-ui/core";
import FacebookIcon from '@material-ui/icons/Facebook';
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import { darkBlueColor } from "Utils";
import { useHistory } from "react-router-dom";
import { yelloColor } from "Utils";
import { whiteColor } from "Utils";
import { linkTextColor } from "Utils";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const history = useHistory();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  return (
    <footer className={footerClasses} style={{ backgroundColor: yelloColor }}>
      <div
        className={classes.container}
        style={{
          marginTop: 30,
          paddingLeft: 50,
          paddingRight: 50,
          textAlign: "left",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            {/* <p
              style={{
                fontSize: 20,
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: darkBlueColor,
                marginBottom: 20,
              }}
            >
              Contact Us
            </p> */}

            <p
              style={{
                fontSize: 25,
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: darkBlueColor,
                marginBottom: 10,
              }}
            >
              Aishwarya College, Pali
            </p>

            <p
              style={{
                fontSize: 16,
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: darkBlueColor,
                marginBottom: 0,
              }}
            >
              Naya Gaon Road, Pali, Rajasthan,
            </p>

            <p
              style={{
                fontSize: 16,
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: darkBlueColor,
                marginBottom: 0,
              }}
            >
              Pin Code - 306401
            </p>

            <p
              style={{
                fontSize: 16,
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: darkBlueColor,
                marginBottom: 0,
              }}
            >
              91666-44756, 94149-77699
            </p>

            <p
              style={{
                fontSize: 16,
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: darkBlueColor,
              }}
            >
              acepalicollege@gmail.com, 
              info@aishwaryacollegepali.edu.in
            </p>

            <a target="_blank" href="https://www.facebook.com/aishwaryacollegepali">
              <FacebookIcon style={{ color: '#3b5998', fontSize: 40 }} />
            </a>
            
          </Grid>

          <Grid item xs={12} sm={12} md={2} lg={2}>
            <p
              style={{
                fontSize: 20,
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: darkBlueColor,
                marginBottom: 20,
              }}
            >
              About
            </p>

            <p
              style={{
                fontSize: 16,
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: "#0a2587",
                marginBottom: 10,
                cursor: "pointer",
              }}
              onMouseEnter={(e) => (
                (e.target.style.color = "red")
              )}
              onMouseLeave={(e) => (
                (e.target.style.color = linkTextColor)
              )}
              onClick={() => history.push("/about-us")}
            >
              About Us
            </p>

            <p
              style={{
                fontSize: 16,
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: linkTextColor,
                marginBottom: 10,
                cursor: "pointer",
              }}
              onMouseEnter={(e) => (
                (e.target.style.color = "red")
              )}
              onMouseLeave={(e) => (
                (e.target.style.color = linkTextColor)
              )}
              onClick={() => history.push("/director-message")}
            >
              Director Message
            </p>

            <p
              style={{
                fontSize: 16,
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: linkTextColor,
                marginBottom: 10,
                cursor: "pointer",
              }}
              onMouseEnter={(e) => (
                (e.target.style.color = "red")
              )}
              onMouseLeave={(e) => (
                (e.target.style.color = linkTextColor)
              )}
              onClick={() => history.push("/chairman-message")}
            >
              Chairman Message
            </p>

            <p
              style={{
                fontSize: 16,
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: linkTextColor,
                marginBottom: 10,
                cursor: "pointer",
              }}
              onMouseEnter={(e) => (
                (e.target.style.color = "red")
              )}
              onMouseLeave={(e) => (
                (e.target.style.color = linkTextColor)
              )}
              onClick={() => history.push("/affiliations")}
            >
              Affiliations
            </p>

            <p
              style={{
                fontSize: 16,
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: linkTextColor,
                marginBottom: 10,
                cursor: "pointer",
              }}
              onMouseEnter={(e) => (
                (e.target.style.color = "red")
              )}
              onMouseLeave={(e) => (
                (e.target.style.color = linkTextColor)
              )}
              onClick={() => history.push("/administration")}
            >
              Administration
            </p>
          </Grid>

          <Grid item xs={12} sm={12} md={2} lg={2}>
            <p
              style={{
                fontSize: 20,
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: darkBlueColor,
                marginBottom: 20,
              }}
            >
              Academics
            </p>

            <p
              style={{
                fontSize: 16,
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: linkTextColor,
                marginBottom: 10,
                cursor: "pointer",
              }}
              onMouseEnter={(e) => (
                (e.target.style.color = "red")
              )}
              onMouseLeave={(e) => (
                (e.target.style.color = linkTextColor)
              )}
              onClick={() => history.push("/bca")}
            >
              Bca
            </p>

            <p
              style={{
                fontSize: 16,
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: linkTextColor,
                marginBottom: 10,
                cursor: "pointer",
              }}
              onMouseEnter={(e) => (
                (e.target.style.color = "red")
              )}
              onMouseLeave={(e) => (
                (e.target.style.color = linkTextColor)
              )}
              onClick={() => history.push("/ba")}
            >
              Ba
            </p>

            <p
              style={{
                fontSize: 16,
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: linkTextColor,
                marginBottom: 10,
                cursor: "pointer",
              }}
              onMouseEnter={(e) => (
                (e.target.style.color = "red")
              )}
              onMouseLeave={(e) => (
                (e.target.style.color = linkTextColor)
              )}
              onClick={() => history.push("/bcom")}
            >
              B.Com
            </p>

            <p
              style={{
                fontSize: 16,
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: linkTextColor,
                marginBottom: 10,
                cursor: "pointer",
              }}
              onMouseEnter={(e) => (
                (e.target.style.color = "red")
              )}
              onMouseLeave={(e) => (
                (e.target.style.color = linkTextColor)
              )}
              onClick={() => history.push("/bsc")}
            >
              Bsc
            </p>
          </Grid>

          <Grid item xs={12} sm={12} md={2} lg={2}>
            <p
              style={{
                fontSize: 20,
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: darkBlueColor,
                marginBottom: 20,
              }}
            >
              Department
            </p>

            <p
              style={{
                fontSize: 16,
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: linkTextColor,
                marginBottom: 10,
                cursor: "pointer",
              }}
              onMouseEnter={(e) => (
                (e.target.style.color = "red")
              )}
              onMouseLeave={(e) => (
                (e.target.style.color = linkTextColor)
              )}
              onClick={() => history.push("/computer-science")}
            >
              Computer Science
            </p>

            <p
              style={{
                fontSize: 16,
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: linkTextColor,
                marginBottom: 10,
                cursor: "pointer",
              }}
              onMouseEnter={(e) => (
                (e.target.style.color = "red")
              )}
              onMouseLeave={(e) => (
                (e.target.style.color = linkTextColor)
              )}
              onClick={() => history.push("/science")}
            >
              Science
            </p>

            <p
              style={{
                fontSize: 16,
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: linkTextColor,
                marginBottom: 10,
                cursor: "pointer",
              }}
              onMouseEnter={(e) => (
                (e.target.style.color = "red")
              )}
              onMouseLeave={(e) => (
                (e.target.style.color = linkTextColor)
              )}
              onClick={() => history.push("/arts")}
            >
              Arts
            </p>

            <p
              style={{
                fontSize: 16,
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: linkTextColor,
                marginBottom: 10,
                cursor: "pointer",
              }}
              onMouseEnter={(e) => (
                (e.target.style.color = "red")
              )}
              onMouseLeave={(e) => (
                (e.target.style.color = linkTextColor)
              )}
              onClick={() => history.push("/commerce")}
            >
              Commerce
            </p>
          </Grid>

          <Grid item xs={12} sm={12} md={2} lg={2}>
            <p
              style={{
                fontSize: 20,
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: darkBlueColor,
                marginBottom: 20,
              }}
            >
              Campus
            </p>

            <p
              style={{
                fontSize: 16,
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: linkTextColor,
                marginBottom: 10,
                cursor: "pointer",
              }}
              onMouseEnter={(e) => (
                (e.target.style.color = "red")
              )}
              onMouseLeave={(e) => (
                (e.target.style.color = linkTextColor)
              )}
              onClick={() => history.push("/library")}
            >
              Library
            </p>

            <p
              style={{
                fontSize: 16,
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: linkTextColor,
                marginBottom: 10,
                cursor: "pointer",
              }}
              onMouseEnter={(e) => (
                (e.target.style.color = "red")
              )}
              onMouseLeave={(e) => (
                (e.target.style.color = linkTextColor)
              )}
              onClick={() => history.push("/lecture-hall")}
            >
              Lecture Hall
            </p>

            <p
              style={{
                fontSize: 16,
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: linkTextColor,
                marginBottom: 10,
                cursor: "pointer",
              }}
              onMouseEnter={(e) => (
                (e.target.style.color = "red")
              )}
              onMouseLeave={(e) => (
                (e.target.style.color = linkTextColor)
              )}
              onClick={() => history.push("/industrial-visit")}
            >
              Industrial Visit
            </p>

            <p
              style={{
                fontSize: 16,
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: linkTextColor,
                marginBottom: 10,
                cursor: "pointer",
              }}
              onMouseEnter={(e) => (
                (e.target.style.color = "red")
              )}
              onMouseLeave={(e) => (
                (e.target.style.color = linkTextColor)
              )}
              onClick={() => history.push("/sports-activities")}
            >
              Sports Activities
            </p>
          </Grid>
        </Grid>

        <div
          className={classes.left}
          style={{ color: darkBlueColor, fontWeight: "bold", marginTop: 100 }}
        >
          {/* Manage By: codeforfuture.in */}
        </div>
        <div
          className={classes.right}
          style={{ color: darkBlueColor, fontWeight: "bold", marginTop: 85 }}
        >
          Copyright &copy; {1900 + new Date().getYear()} Aishwarya College Of
          Education, Pali | All rights reserved.
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
