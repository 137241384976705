import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import culturalImage from "assets/img/Cultural.png";
import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";
import { darkBlueColor, yelloColor, apiUrl } from "../../../Utils/index";
import axios from "axios";
import "./marquee.css"

const useStyles = makeStyles(styles);

export default function TeamSection() {
  const history = useHistory();
  const classes = useStyles();
  const [noticeBoardData, setNoticeBoardData] = useState([]);

  useEffect(() => {
    axios({
      method: "GET",
      url: apiUrl + "/noticeboard",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        setNoticeBoardData(res.data);
      })
      .catch((error) => {
        console.log("Error", error);
        setNoticeBoardData([]);
      });
  }, []);

  return (
    <div
      className={classes.section}
      style={{ textAlign: "left", marginTop: -50 }}
    >
      <p
        style={{
          color: darkBlueColor,
          fontSize: 26,
          fontWeight: "bold",
          fontFamily: "Rubik, sans-serif",
          marginBottom: 30,
        }}
      >
        Campus Life
      </p>
      <img width={"100%"} height={500} src={culturalImage} />

      {Array.isArray(noticeBoardData) && noticeBoardData?.length > 0 && (
        <Grid
          container
          spacing={3}
          style={{
            marginTop: 50,
            backgroundColor: darkBlueColor,
            paddingTop: 20,
            paddingBottom: 20,
            paddingLeft: 10,
            paddingRight: 10,
            textAlign: "center",
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container>
            <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <p
                  style={{
                    color: "white",
                    fontSize: 26,
                    fontWeight: "bold",
                    fontFamily: "Rubik, sans-serif",
                    marginBottom: 30,
                  }}
                >
                  Notice & Events
                </p>
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <p
                  style={{
                    color: yelloColor,
                    fontSize: 20,
                    fontWeight: "bold",
                    fontFamily: "Rubik, sans-serif",
                    marginBottom: 30,
                    float: 'right',
                    cursor: 'pointer'
                  }}
                  onClick={() => history.push("/notice-board")}
                >
                  View all
                </p>
              </Grid>
            </Grid>
            <div class="container blur">
            <div class="slider">
             
              {noticeBoardData &&
              Array.isArray(noticeBoardData) &&
                noticeBoardData?.length > 0 &&
                noticeBoardData?.map((value, index) => {
                  return (
                    notice(value)
                  );
                  function notice(value) {
                    if(value.attachmentId===""){
                      return <Card
                        style={{
                          backgroundColor: yelloColor,
                          paddingLeft: 20,
                          paddingRight: 20,
                          paddingTop: 20,
                          paddingBottom: 10,
                          marginBottom: 20
                        }}
                      >
                        <Grid container>
                          <p
                            style={{
                              color: darkBlueColor,
                              fontSize: 19,
                              fontWeight: "bold",
                            }}
                          >
                            {value.title}
                          </p>
                          </Grid>
                          <Grid container>
                          <p
                            style={{
                              color: "black",
                              fontSize: 12,
                              fontWeight: "bold",
                              marginLeft: 5
                            }}
                          >
                            {value.description}
                          </p>
                        </Grid>
                      </Card>;
                    }
                    else{
                      return <a
                      target="_blank"
                      href={apiUrl + "/download/" + value.attachmentId}
                      style={{
                        textDecoration: "none",
                        textDecorationColor: "none",
                        color: "black",
                      }}
                      class="button"
                      key={index}
                    >
                      <Card
                        style={{
                          backgroundColor: yelloColor,
                          paddingLeft: 20,
                          paddingRight: 20,
                          paddingTop: 20,
                          paddingBottom: 10,
                          marginBottom: 20,
                          cursor: "pointer",
                        }}
                      >
                        <Grid container>
                          <p
                            style={{
                              color: darkBlueColor,
                              fontSize: 19,
                              fontWeight: "bold",
                            }}
                          >
                            {value.title}
                          </p>
                          <p
                            style={{
                              color: "red",
                              fontSize: 12,
                              fontWeight: "bold",
                              marginLeft: 5
                            }}
                          >
                            *Click here
                          </p>
                          </Grid>
                          <Grid container>
                          <p
                            style={{
                              color: "black",
                              fontSize: 12,
                              fontWeight: "bold",
                              marginLeft: 5
                            }}
                          >
                            {value.description}
                          </p>
                        </Grid>
                      </Card>
                    </a>;
                    }
                  }
                })}
                </div>
                </div>
          </Grid>
        </Grid>
      )}
      
    </div>
  );
}
