import React, { useEffect, useState } from "react";
import classNames from "classnames";
import {
  Grid,
  CircularProgress,
  Card,
  Typography
} from "@material-ui/core";
import Gallery from 'react-grid-gallery';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import culturalImage from "assets/img/Cultural.png";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { apiUrl, darkBlueColor, whiteColor } from "../../Utils/index";

const axios = require("axios");

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function Gallerys(props) {
    const classes = useStyles();
    const { ...rest } = props;
    const[loading, setLoading] = useState(false);
    const[gallery, setGallery] = useState([]);
    const[catValue, setCatValue] = useState("all");
    const[catSelected, setCatSelected] = useState(false);

    useEffect(() => {
        setLoading(true);
        window.scrollTo(0, 0);
        axios({
          method: "GET",
          url: apiUrl + "/gallery",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            setGallery(res.data);
            setLoading(false);
          })
          .catch((error) => {
            console.log("Error", error);
          });
      }, []);

    const galleryImages = gallery.map((value) => {
        return {
            src: apiUrl + "/download/" + value.image,
            thumbnail: apiUrl + "/download/" + value.image,
            thumbnailWidth: 200,
            thumbnailHeight: 100,
            thumbnailCaption: value.title,
            caption: value.title,
            category: value.category
        }
    });

    let categoryData = [];
    const ids = gallery.map(o => o.category)
    categoryData = gallery.filter(({category}, index) => !ids.includes(category, index + 1))

    let allGalleryImage = [];

    if(catValue === 'all'){
        allGalleryImage = galleryImages
    } else {
        allGalleryImage = galleryImages?.filter((e) => e.category === catValue)
    }
    
    return(
        <div>
            <Header
                color="white"
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll={{
                height: 200,
                color: "white",
                }}
                {...rest}
            />
            
            <div
                className={classNames(classes.main)}
                style={{ marginTop: 110, backgroundColor: "white" }}
            >
                <div className={classes.container} style={{ padding: 20, paddingTop: 50 }}>
                    {!catSelected ?
                        <h2
                            style={{
                                fontSize: "30px",
                                fontFamily: "Rubik, sans-serif",
                                fontWeight: "bold",
                                color: darkBlueColor,
                                marginBottom: 20,
                            }}
                        >
                            Gallery
                        </h2>
                    :
                        <h2
                            style={{
                                fontSize: "30px",
                                fontFamily: "Rubik, sans-serif",
                                fontWeight: "bold",
                                color: darkBlueColor,
                                marginBottom: 20,
                            }}
                            onClick={()=> {
                                setCatValue("all")
                                setCatSelected(false)
                            }}
                        >
                            <ArrowBackIcon style={{ color: darkBlueColor }} /> {catValue === 'all' ? "Gallery" : catValue}
                        </h2>
                    }

                    {loading ? 
                        <div style={{ textAlign: 'center', marginBottom: 200, marginTop: 200 }}>
                            <CircularProgress /> 
                        </div>
                    :
                        <>
                            {!catSelected ?
                                <Grid
                                    container
                                    spacing={3}
                                    style={{
                                        marginTop: 20,
                                        marginBottom: 20,
                                    }}
                                >
                                    <Grid item xs={12} sm={12} md={3} lg={3} 
                                        onClick={()=> {
                                            setCatValue("all")
                                            setCatSelected(true)
                                        }}
                                        style={{ cursor: 'pointer' }}>
                                        <img width={'100%'} height={300} src={culturalImage} />
                                        <Typography style={{ padding: 10, backgroundColor: darkBlueColor, color: "white", fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>All Images</Typography>
                                    </Grid>
                                    {categoryData && categoryData.length > 0 ?
                                        categoryData && categoryData?.map((val, key) => {
                                            return(
                                                <Grid key={key} item xs={12} sm={12} md={3} lg={3} 
                                                    onClick={()=> {
                                                        setCatValue(val.category)
                                                        setCatSelected(true)
                                                    }} 
                                                    style={{ cursor: 'pointer' }}>
                                                    <img width={'100%'} height={300} src={apiUrl + "/download/" + val.image} />
                                                    <Typography style={{ padding: 10, backgroundColor: darkBlueColor, color: "white", fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>{val.category}</Typography>
                                                </Grid>
                                            )
                                        })
                                    :
                                        <div style={{ textAlign: 'center' }}>
                                            <h2
                                                style={{
                                                    fontSize: "30px",
                                                    fontFamily: "Rubik, sans-serif",
                                                    fontWeight: "bold",
                                                    color: darkBlueColor,
                                                    marginTop: 100,
                                                    marginBottom: 200,
                                                }}
                                            >
                                                No Data
                                            </h2>
                                        </div>
                                    }
                                </Grid>
                            :
                                null
                            }

                            {catSelected ? 
                                <div>
                                    <Gallery 
                                        rowHeight={330}
                                        margin={5}
                                        enableImageSelection={false}
                                        backdropClosesModal={true}
                                        showLightboxThumbnails={true}
                                        images={allGalleryImage}
                                    />

                                    <Grid
                                        container
                                        spacing={3}
                                        style={{
                                            marginTop: 20,
                                            marginBottom: 20
                                        }}
                                    >
                                        <Grid item xs={12} sm={12} md={3} lg={3}>
                                            {/* <img width={'100%'} height={350} src={NareshPurohitImage} /> */}
                                        </Grid>
                                    </Grid>
                                </div>
                                :
                                null
                            }
                        </>
                    }
                </div>
            </div>
        <Footer />
    </div>
    )
}