import React, { useEffect } from "react";
import classNames from "classnames";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { darkBlueColor, grayTextColor } from "../Utils/index";
import director from "assets/img/director.png";

const useStyles = makeStyles(styles);

export default function DirectorMessage(props) {
  const classes = useStyles();
  const { ...rest } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header
        color="white"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <div
        className={classNames(classes.main)}
        style={{ marginTop: 110, backgroundColor: "white" }}
      >
        <div>
          <div className={classes.container} style={{ padding: 30 }}>
            <h2
              style={{
                fontSize: "30px",
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: darkBlueColor,
                marginBottom: 40,
              }}
            >
              Director’s Message
            </h2>

            <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={3} lg={3}>
                <div style={{ textAlign: "center", marginBottom: 40 }}>
                  <img
                    width={"90%"}
                    height={"100%"}
                    src={director}
                    alt="image"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={9} lg={9}>
                <div>
                  <p
                    style={{
                      color: grayTextColor,
                      fontSize: 16,
                      fontFamily: "Rubik, sans-serif",
                      textAlign: "justify",
                    }}
                  >
                    In the new knowledge age, intellectual competitiveness is
                    essential for achieving the status of a leader. For this,
                    quality education, more so with a lot practical orientation
                    is of paramount importance. Our teaching and learning
                    methodology involves a lot of conceptual approach along with
                    clear fundamentals &amp; coupled with a good practical
                    understanding to deal with real world problems effectively
                    and efficiently.
                  </p>

                  <p
                    style={{
                      color: grayTextColor,
                      fontSize: 16,
                      fontFamily: "Rubik, sans-serif",
                      textAlign: "justify",
                    }}
                  >
                    Our students have infiltrated into all sectors be it
                    government, private or public to serve the country in
                    various capacities. The school strives to inculcate moral
                    values, manners, responsibilities among students so that
                    they may build a world free of all the evils. Students here
                    are encouraged to dream, groomed to give wings to their
                    dreams, to take off and explore the unexplored. Since its
                    inception, the institute has not only grown up in its
                    infrastructure but has also developed a lot in terms of
                    academics and laboratories. A well-balanced curriculum,
                    taught by qualified, skilled, and experienced faculty
                    develops the required skills in students necessary to meet
                    tomorrow’s applications. We are in a continuous process of
                    making our laboratories well equipped to meet the demands of
                    the rapidly changing related science &amp; technology
                    fields.
                  </p>

                  <h5
                    style={{
                      marginTop: 30,
                      fontSize: 16,
                      fontWeight: "bold",
                      color: grayTextColor,
                      fontFamily: "Rubik, sans-serif",
                    }}
                  >
                    I welcome you all to this great institution of higher
                    learning and assure you of a nurturing and caring
                    environment that will see all of you blossom into empowered
                    and sensitive human beings.
                  </h5>

                  <h5
                    style={{
                      marginTop: 50,
                      fontSize: 20,
                      fontWeight: "bold",
                      color: darkBlueColor,
                      marginBottom: -10,
                      fontFamily: "Rubik, sans-serif",
                      textAlign: 'right'
                    }}
                  >
                    Tajveer Singh Rathore
                  </h5>

                  <h5
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                      color: grayTextColor,
                      fontFamily: "Rubik, sans-serif",
                      textAlign: 'right'
                    }}
                  >
                    (Managing Director)
                  </h5>
                </div>
              </Grid>
              
            </Grid>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
