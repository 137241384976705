import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import "assets/scss/material-kit-react.scss?v=1.10.0";
// pages for this product
// import Components from "views/Components/Components.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import AboutUs from "views/AboutUs.js";
import DirectorMessage from "views/DirectorMessage.js";
import ChairmanMessage from "views/ChairmanMessage.js";
import Bca from "views/Courses/Bca.js";
import Ba from "views/Courses/Ba.js";
import Bsc from "views/Courses/Bsc.js";
import Bcom from "views/Courses/Bcom.js";
import Fee from "views/Admission/Fee.js";
import Affiliations from "views/Affiliations.js";
import Courses from "views/Courses.js";
import Library from "views/Campus/Library";
import ComputerLab from "views/Campus/ComputerLab";
import ChemistryLab from "views/Campus/ChemistryLab";
import PhysicsLab from "views/Campus/PhysicsLab";
import DigitalLab from "views/Campus/DigitalLab";
import ZoologyLab from "views/Campus/ZoologyLab";
import BotanyLab from "views/Campus/BotanyLab";
import LectureHall from "views/Campus/LectureHall";
import SportsActivities from "views/Campus/SportsActivities";
import CulturalActivities from "views/Campus/CulturalActivities";
import IndustrialVisit from "views/Campus/IndustrialVisit";
import ContactUs from "views/contactUs";
import ComputerScience from "views/Department/ComputerScience";
import Science from "views/Department/Science";
import Commerce from "views/Department/Commerce";
import Arts from "views/Department/Arts";
import Administration from "views/Administration";
import AllNoticeBoard from "views/AllNoticeBoard";
import Gallery from "views/Gallery/Gallery";
import AssignmentCourseList from "views/StudentCorner/Assignment/AssignmentCourseList";
import PreviousPaperCourseList from "views/StudentCorner/PreviousPaper/PreviousPaperCourseList";
import Assignment from "views/StudentCorner/Assignment/Assignement";
import PageNotFound from "views/PageNotFound";
import PreviousPaperYear from "views/StudentCorner/PreviousPaper/PreviousPaperYear";
import PreviousPaper from "views/StudentCorner/PreviousPaper/PreviousPaper";
import Training from "views/Tpo/Training";
import Placement from "views/Tpo/Placement";
import Alumni from "views/Alumni/Alumni";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route exact path="/" component={LandingPage} />
      <Route exact path="/about-us" component={AboutUs} />
      <Route exact path="/director-message" component={DirectorMessage} />
      <Route exact path="/chairman-message" component={ChairmanMessage} />
      <Route exact path="/bca" component={Bca} />
      <Route exact path="/ba" component={Ba} />
      <Route exact path="/bcom" component={Bcom} />
      <Route exact path="/bsc" component={Bsc} />
      <Route exact path="/fee-structure" component={Fee} />
      <Route exact path="/affiliations" component={Affiliations} />
      <Route exact path="/courses" component={Courses} />

      {/* Campus Tab Start */}
      <Route exact path="/library" component={Library} />
      <Route exact path="/computer-lab" component={ComputerLab} />
      <Route exact path="/chemistry-lab" component={ChemistryLab} />
      <Route exact path="/physics-lab" component={PhysicsLab} />
      <Route exact path="/digital-electronics-lab" component={DigitalLab} />
      <Route exact path="/zoology-lab" component={ZoologyLab} />
      <Route exact path="/botany-lab" component={BotanyLab} />
      <Route exact path="/lecture-hall" component={LectureHall} />
      <Route exact path="/sports-activities" component={SportsActivities} />
      <Route exact path="/cultural-activities" component={CulturalActivities} />
      <Route exact path="/industrial-visit" component={IndustrialVisit} />
      <Route exact path="/contact-us" component={ContactUs} />
      <Route exact path="/gallery" component={Gallery} />
      <Route exact path="/notice-board" component={AllNoticeBoard} />
      {/* Campus Tab End */}

      {/* Department Tab Start */}
      <Route exact path="/computer-science" component={ComputerScience} />
      <Route exact path="/science" component={Science} />
      <Route exact path="/commerce" component={Commerce} />
      <Route exact path="/arts" component={Arts} />
      <Route exact path="/administration" component={Administration} />

      {/* Student Corner */}
      <Route exact path="/assignment" component={AssignmentCourseList} />
      <Route exact path="/assignment/*" component={Assignment} />

      <Route exact path="/previous-paper" component={PreviousPaperCourseList} />
      <Route exact path="/previous-paper/*" component={PreviousPaperYear} />
      <Route exact path="/paper/*/*" component={PreviousPaper} />

      {/* TPO */}
      <Route exact path="/training" component={Training} />
      <Route exact path="/placement" component={Placement} />

      {/* alumni */}
      <Route exact path="/alumni" component={Alumni} />

    </Switch>
  </Router>,
  document.getElementById("root")
);
