import React, { useEffect, useState } from "react";
import Header from "../../../components/Header/Header";
import {
  Grid,
  Card,
  Breadcrumbs,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import HeaderLinks from "../../../components/Header/HeaderLinks";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { darkBlueColor, whiteColor, apiUrl } from "../../../Utils/index";
import Footer from "components/Footer/Footer";

const axios = require("axios");
const useStyles = makeStyles(styles);

export default function PreviousPaper(props) {
    const history = useHistory();
  const classes = useStyles();
  const { ...rest } = props;

  const [ppData, setPpData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [courseName, setCourseName] = useState("Course");
  const [year, setYear] = useState("year");

  useEffect(() => {
    setLoading(true);
    let courseId = window.location.pathname.toUpperCase();

    if(courseId){
        window.scrollTo(0, 0);
        axios({
        method: "GET",
        url: apiUrl + "/previouspaper",
        headers: {
            "Content-Type": "application/json",
        },
        })
        .then((res) => {
            let data = res.data.filter((val) => "/PAPER/" + val.courseId + "/" + val.year == courseId);
            setPpData(data);
            let urlstr = courseId.split("/");
             let year = urlstr[urlstr.length - 1];
            setCourseName(data[0].courseId);
            setYear(year);
            setLoading(false);
        })
        .catch((error) => {
            console.log("Error", error);
        });
    }
  }, []);

  return (
    <div>
        <Header
            color="white"
            rightLinks={<HeaderLinks />}
            fixed
            changeColorOnScroll={{
            height: 200,
            color: whiteColor,
            }}
            {...rest}
        />
        <div
            className={classNames(classes.main)}
            style={{ marginTop: 110, backgroundColor: whiteColor }}
        >
            <div>
                <div className={classes.container} style={{ padding: 30 }}>
                    {loading ? 
                        null 
                        :
                        <div>
                            <h2
                                style={{
                                    fontSize: "30px",
                                    fontFamily: "Rubik, sans-serif",
                                    fontWeight: "bold",
                                    color: darkBlueColor,
                                    marginBottom: 20,
                                }}
                            >
                                {courseName}
                            </h2>

                            <Breadcrumbs aria-label="breadcrumb">
                                <Typography style={{ cursor: 'pointer' }} onClick={()=> history.push('/previous-paper')} >
                                    Previous Paper
                                </Typography>
                                <Typography style={{ cursor: 'pointer' }} onClick={()=> history.push('/previous-paper/' + courseName.toLowerCase())} >
                                    {courseName}
                                </Typography>
                                <Typography color="text.primary">{year}</Typography>
                            </Breadcrumbs>
                        </div>
                    }

                        {loading ? 
                            <div className={classes.container} style={{ textAlign: 'center', marginBottom: 200, marginTop: 200 }}>
                                <CircularProgress /> 
                            </div>
                            :
                            <div style={{ marginTop: 20 }}>
                                <Grid
                                    container
                                    spacing={2}
                                    style={{ marginBottom: 20 }}
                                >
                                    {ppData && ppData.length > 0 ? 
                                        ppData.map((value, index) => {
                                        return(
                                            <Grid key={index} item xs={12} sm={12} md={12} lg={12}>
                                                <a
                                                    target="_blank"
                                                    href={apiUrl + "/download/" + value.pdf}
                                                >
                                                    <Card
                                                        style={{
                                                            borderRadius: 10,
                                                            paddingLeft: 20,
                                                            paddingBottom: 10,
                                                            // padding: 30,
                                                            backgroundColor: darkBlueColor,
                                                        }}
                                                    >
                                                        <h2
                                                            style={{
                                                                fontSize: 16,
                                                                fontWeight: 'bold',
                                                                fontFamily: "Rubik, sans-serif",
                                                                color: whiteColor,
                                                            }}
                                                        >
                                                        {value.title}
                                                        </h2>
                                                    </Card>
                                                </a>
                                            </Grid>
                                        )
                                    })  :
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <h2
                                            style={{
                                                fontSize: "30px",
                                                fontFamily: "Rubik, sans-serif",
                                                fontWeight: "bold",
                                                color: darkBlueColor,
                                                marginTop: 100,
                                                marginBottom: 200,
                                            }}
                                        >
                                            No Data
                                        </h2>
                                    </Grid>
                                    } 
                                </Grid>
                            </div>
                        }
                    </div>
            </div>
        </div>
      <Footer />
    </div>
  )
}