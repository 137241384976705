import React, { useEffect } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { darkBlueColor, grayTextColor } from "../../Utils/index";
import LectureHallImage from "assets/img/LectureHall.png";

const useStyles = makeStyles(styles);

export default function LectureHall(props) {
  const classes = useStyles();
  const { ...rest } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header
        color="white"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <div
        className={classNames(classes.main)}
        style={{ marginTop: 110, backgroundColor: "white" }}
      >
        <div>
          <div className={classes.container} style={{ padding: 30 }}>
            <h2
              style={{
                fontSize: "30px",
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: darkBlueColor,
                marginBottom: 10,
              }}
            >
              Lecture Hall
            </h2>

            <div style={{ marginBottom: 50, textAlign: 'center' }}>
              <img width={"50%"} height={"50%"} src={LectureHallImage} />
            </div>

            <div>
              <p
                style={{
                  color: grayTextColor,
                  fontSize: 16,
                  fontFamily: "Rubik, sans-serif",
                  textAlign: "justify",
                }}
              >
                The Lecture Halls are provided with air-cooled excellent seating
                arrangements and latest audio visual equipments. Audio visual
                aids provided in the lecture halls include sound amplifier
                system, overhead projection, 35 mm slide projection and LCD
                projections apart from the traditional black board and chalk.
                The lecture halls have also been equipped with facilities for
                E-learning with digital boards.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
