import React, { useEffect } from "react";
import classNames from "classnames";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { darkBlueColor, grayTextColor } from "../../Utils/index";
import computerlabImage1 from "assets/img/computerlab1.jpg";
import computerlabImage2 from "assets/img/computerlab2.jpg";

const useStyles = makeStyles(styles);

export default function ComputerLab(props) {
  const classes = useStyles();
  const { ...rest } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header
        color="white"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <div
        className={classNames(classes.main)}
        style={{ marginTop: 110, backgroundColor: "white" }}
      >
        <div>
          <div className={classes.container} style={{ padding: 30 }}>
            <h2
              style={{
                fontSize: "30px",
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: darkBlueColor,
                marginBottom: 40,
              }}
            >
              Computer Lab
            </h2>

            <div style={{ marginTop: 50, marginBottom: 50 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <img width={"100%"} height={"100%"} src={computerlabImage1} />
                </Grid>
                <Grid item xs={6}>
                  <img width={"100%"} height={"100%"} src={computerlabImage2} />
                </Grid>
              </Grid>
            </div>

            <div>
              <p
                style={{
                  color: grayTextColor,
                  fontSize: 16,
                  fontFamily: "Rubik, sans-serif",
                  textAlign: "justify",
                }}
              >
                The primary goal of a well-equipped computer lab at ACE-Pali is
                to provide our students with assistance that enhances their
                possibilities of succeeding in their assignments. Completely
                air-conditioned lab has systems which are interconnected via
                high-speed LAN that further has hubs and switches. Our computer
                lab provides the students as well as faculty with high bandwidth
                internet speed thereby making them proficient in keeping
                themselves abreast of all the advanced technological
                developments that happen around the world. Lab equipped with
                powerful backup 24x7. We encourage our students to carry out
                deeper research for their projects. Through this, they can build
                their hands-on experience and upgrade their learning skills.
              </p>

              <h2
                style={{
                  fontSize: 16,
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "bold",
                  color: darkBlueColor,
                  marginTop: 40,
                }}
              >
                The following configuration installed in the computer lab:
              </h2>

              <List
                component="nav"
                className={classes.root}
                aria-label="contacts"
              >
                <ListItem>
                  <ListItemIcon>
                    <StarIcon style={{ color: darkBlueColor }} />
                  </ListItemIcon>
                  <ListItemText style={{ color: grayTextColor }} primary="SERVER: core i7 with 16GB RAM – 2 TB Hard-disk" />
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <StarIcon style={{ color: darkBlueColor }} />
                  </ListItemIcon>
                  <ListItemText style={{ color: grayTextColor }} primary="Systems: core i5 with 8GB RAM –  500 GB Hard-disk" />
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <StarIcon style={{ color: darkBlueColor }} />
                  </ListItemIcon>
                  <ListItemText style={{ color: grayTextColor }} primary="Advanced Networking Equipment with 100Mbps" />
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <StarIcon style={{ color: darkBlueColor }} />
                  </ListItemIcon>
                  <ListItemText style={{ color: grayTextColor }} primary="FTTH internet/WiFi with 200Mbps " />
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <StarIcon style={{ color: darkBlueColor }} />
                  </ListItemIcon>
                  <ListItemText style={{ color: grayTextColor }} primary="CCTV Surveillance security system" />
                </ListItem>
              </List>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}