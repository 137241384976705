import React, { useEffect } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { darkBlueColor, grayTextColor } from "../../Utils/index";
import zoologyLabImage from "assets/img/zoologyLabImage.png";

const useStyles = makeStyles(styles);

export default function ZoologyLab(props) {
  const classes = useStyles();
  const { ...rest } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header
        color="white"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <div
        className={classNames(classes.main)}
        style={{ marginTop: 110, backgroundColor: "white" }}
      >
        <div>
          <div className={classes.container} style={{ padding: 30 }}>
            <h2
              style={{
                fontSize: "30px",
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: darkBlueColor,
                marginBottom: 10,
              }}
            >
              Zoology Lab
            </h2>

            <div style={{ marginBottom: 50, textAlign: 'center' }}>
              <img width={"50%"} height={"50%"} src={zoologyLabImage} />
            </div>

            <div>
              <p
                style={{
                  color: grayTextColor,
                  fontSize: 16,
                  fontFamily: "Rubik, sans-serif",
                  textAlign: "justify",
                }}
              >
                The lab work undertaken is to provide practical training about
                Invertebrates, Vertebrates & animal physiology etc. to
                undergraduate students Sphygmomanometer, Haemoglobinometer,
                Blood group testing kit , RBC , WBC counting apparatus ,animal
                dissection apparatuses ,compound microscopes are instruments
                present in lab. Zoology lab is providing basic and advance
                knowledge about the animals by morphological, physiological, and
                anatomical aspects (by dissection). It is helping the students
                of B.Sc for better understanding of animal models which are used
                in this field.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}