import React, { useEffect } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { darkBlueColor, grayTextColor } from "../../Utils/index";
import chemistryLabImage from "assets/img/chemistryLabImage.png";

const useStyles = makeStyles(styles);

export default function ChemistryLab(props) {
  const classes = useStyles();
  const { ...rest } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header
        color="white"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <div
        className={classNames(classes.main)}
        style={{ marginTop: 110, backgroundColor: "white" }}
      >
        <div>
          <div className={classes.container} style={{ padding: 30 }}>
            <h2
              style={{
                fontSize: "30px",
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: darkBlueColor,
                marginBottom: 10,
              }}
            >
              Chemistry Lab
            </h2>

            <div style={{ marginBottom: 50, textAlign: 'center' }}>
              <img width={"50%"} height={"50%"} src={chemistryLabImage} />
            </div>

            <div>
              <p
                style={{
                  color: grayTextColor,
                  fontSize: 16,
                  fontFamily: "Rubik, sans-serif",
                  textAlign: "justify",
                }}
              >
                ACE-Pali has state-of-the-art Chemistry lab that lets you sat it
                your curiosity by moving the students in the correct direction.
                The equipments are all calibrated for zero error. The table tops
                are made of epoxy resin and phenolic resin and can withstand the
                hardest chemical and leaves no marks or reactions on surface.
                The lab is created to be fire resistant and handle any kind of
                mishap.
              </p>

              <p
                style={{
                  color: grayTextColor,
                  fontSize: 16,
                  fontFamily: "Rubik, sans-serif",
                  textAlign: "justify",
                }}
              >
                The heights and widths of the furniture are all created keeping
                in mind the Indian physique of students. All the drawers and
                cupboards doors are made for soft closing. The windows and
                environmental friendly lighting are especially designed keeping
                in mind visibility. Our laboratories with advanced equipments
                and facilities aid and stimulate our students for them to learn
                best with practical knowledge. It empowers them with better
                comprehension and moves their inquisitiveness in the correct
                direction.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}