import React, { useEffect } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { darkBlueColor, grayTextColor } from "../../Utils/index";
import physicsImage from "assets/img/physicsImage.png";

const useStyles = makeStyles(styles);

export default function PhysicsLab(props) {
  const classes = useStyles();
  const { ...rest } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header
        color="white"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <div
        className={classNames(classes.main)}
        style={{ marginTop: 110, backgroundColor: "white" }}
      >
        <div>
          <div className={classes.container} style={{ padding: 30 }}>
            <h2
              style={{
                fontSize: "30px",
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: darkBlueColor,
                marginBottom: 10,
              }}
            >
              Physics Lab
            </h2>

            <div style={{ marginBottom: 50, textAlign: 'center' }}>
              <img width={"50%"} height={"50%"} src={physicsImage} />
            </div>

            <div>
              <p
                style={{
                  color: grayTextColor,
                  fontSize: 16,
                  fontFamily: "Rubik, sans-serif",
                  textAlign: "justify",
                }}
              >
                The Physics laboratory is spacious, well equipped with modern &
                advanced instruments for conducting lab experiments in various
                important areas of physics like optics, mechanics, electronics &
                electricity, electromagnetic fields, thermal etc. which drives
                the students to various technical areas. All the experiments are
                designed to correlate theoretical knowledge with the practical
                applications. A computer simulation of the difficult experiments
                is also demonstrated to the students for better understanding of
                the concepts.
              </p>

              <p
                style={{
                  color: grayTextColor,
                  fontSize: 16,
                  fontFamily: "Rubik, sans-serif",
                  textAlign: "justify",
                }}
              >
                We harness the best of modern technology to support the
                intellectual curiosity of our students and faculty and to bestow
                best laboratory experience.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}