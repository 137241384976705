import React,{ useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";

const useStyles = makeStyles(styles);

export default function Courses(props) {
  const classes = useStyles();
  const { ...rest } = props;
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header
        color="white"
        // brand="Aishwarya College of Education, Pali"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Parallax
        small
        filter
        image={require("assets/img/profile-bg.jpg").default}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container} style={{ padding: 20 }}>
            <h2>Courses</h2>
            <div className={classes.container}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <h5 style={{ fontWeight: "bold" }}>BCA</h5>
                  <p>
                    Qualification:-48% in XII with any discipline (RBSE /CBSE
                    /NIOS) Admission Seats:-120
                  </p>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <h5 style={{ fontWeight: "bold" }}>B.Com</h5>
                  <p>
                    Qualification:-48% in XII Commerce or Science Students (RBSE
                    /CBSE /NIOS) Admission Seats:-60
                  </p>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <h5 style={{ fontWeight: "bold" }}>B.Sc (Science Maths)</h5>
                  <p>
                    Qualification:-48% in XII Science Maths Students (RBSE /CBSE
                    /NIOS) Subjects: Physics, Maths, Chemistry/Computer Science
                    Admission Seats:-60
                  </p>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <h5 style={{ fontWeight: "bold" }}>B.Sc (Science Bio)</h5>
                  <p>
                    Qualification:- 48% in XII Science Bio Students (RBSE /CBSE
                    /NIOS) Subjects: Chemistry, Botany, Zoology Admission
                    Seats:-60
                  </p>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <h5 style={{ fontWeight: "bold" }}>B.A (Arts)</h5>
                  <p>
                    Qualification:-48% in XII Arts Students (RBSE /CBSE /NIOS)
                    Subjects: Hindi Lit., Pol. Science, History, Geography
                    Admission Seats:-120
                  </p>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
