import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  Breadcrumbs,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import HeaderLinks from "../../components/Header/HeaderLinks";
import Header from "../../components/Header/Header";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { darkBlueColor, whiteColor, apiUrl } from "../../Utils/index";
import Footer from "components/Footer/Footer";

const axios = require("axios");
const useStyles = makeStyles(styles);

export default function Alumni(props) {
    const history = useHistory();
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div>
        <Header
            color="white"
            rightLinks={<HeaderLinks />}
            fixed
            changeColorOnScroll={{
            height: 200,
            color: whiteColor,
            }}
            {...rest}
        />
        <div
            className={classNames(classes.main)}
            style={{ marginTop: 110, backgroundColor: whiteColor }}
        >
          <div className={classes.container} style={{ padding: 30 }}>
            <h2
              style={{
                  fontSize: "30px",
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "bold",
                  color: darkBlueColor,
                  marginBottom: 20,
              }}
            >
                Alumni
            </h2>
          </div>  

          <div style={{ padding: 30, textAlign: 'center' }}>
            <h2
              style={{
                  fontSize: "30px",
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "bold",
                  color: darkBlueColor,
                  marginTop: 100,
                  marginBottom: 200,
              }}
            >
                Coming Soon
            </h2>
          </div>  


        </div>
      <Footer/>
    </div>
  )
}