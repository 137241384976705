import React, { useEffect } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { darkBlueColor, grayTextColor } from "../../Utils/index";
import CulturalImage from "assets/img/Cultural.png";

const useStyles = makeStyles(styles);

export default function CulturalActivities(props) {
  const classes = useStyles();
  const { ...rest } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header
        color="white"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <div
        className={classNames(classes.main)}
        style={{ marginTop: 110, backgroundColor: "white" }}
      >
        <div>
          <div className={classes.container} style={{ padding: 30 }}>
            <h2
              style={{
                fontSize: "30px",
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: darkBlueColor,
                marginBottom: 10,
              }}
            >
              Cultural Activities
            </h2>

            <div style={{ marginBottom: 50, textAlign: 'center' }}>
              <img width={"50%"} height={"50%"} src={CulturalImage} />
            </div>

            <div>
              <p
                style={{
                  color: grayTextColor,
                  fontSize: 16,
                  fontFamily: "Rubik, sans-serif",
                  textAlign: "justify",
                }}
              >
                Today’s education system is not just confined to academics but
                modern-day schools focus on imparting skills to students like
                personality development, confidence building, communication
                skills, etiquettes, etc. Cultural activities come into play in
                instilling these skills in the students.
              </p>

              <p
                style={{
                  color: grayTextColor,
                  fontSize: 16,
                  fontFamily: "Rubik, sans-serif",
                  textAlign: "justify",
                }}
              >
                Encouraging students to participate in cultural activities is
                the most effective way to inculcate personality-related skills.
                These include activities like dancing, singing, sports, etc.
                Unfortunately, many parents and students find these activities
                as total waste of time and energy. However, the truth is
                entirely different as cultural activities enable students to
                gain confidence and understand their culture in a much better
                way.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
